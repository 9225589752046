import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useDeleteClientFile,
  useUpdateClientFile,
} from "../../../hooks/useFiles/useFiles";
import Badge from "../../../components/common/badges/Badge";

const FileDetails = ({ data, closeSidebar }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fileData, setFileData] = useState(data);
  const [showOptions, setShowOptions] = useState(false);
  const [initialFileData, setInitialFileData] = useState(data);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const deleteFile = useDeleteClientFile();
  const updateFile = useUpdateClientFile();

  useEffect(() => {
    setFileData(data);
    setInitialFileData(data);
  }, [data]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await deleteFile.mutateAsync({ fileId: fileData.id });
        closeSidebar();
        navigate(`/projects/${fileData.project_id}`);
      } catch (err) {
        setError((prevError) => ({
          ...prevError,
          submit: "Failed to delete file. Please try again.",
        }));
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFileData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateInputs = () => {
    const errors = {};
    if (!fileData.name.trim()) errors.name = "Name is required.";
    if (!fileData.file_url.trim()) errors.file_url = "File URL is required.";
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    setShowOptions(false);
    if (!validateInputs()) return;
    try {
      await updateFile.mutateAsync({
        fileId: fileData.id,
        data: fileData,
      });
      setInitialFileData(fileData);
      setIsEditing(false);
    } catch (err) {
      setError((prevError) => ({
        ...prevError,
        submit: "Failed to update file. Please try again.",
      }));
    }
  };

  const handleCancelEdit = () => {
    setFileData(initialFileData);
    setIsEditing(false);
    setShowOptions(false);
    setError({});
  };

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div
          className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg"
          role="alert"
        >
          {error.submit}
        </div>
      )}
      {isEditing ? (
        <div className="space-y-4">
          {/* File Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              File Name
            </label>
            <input
              type="text"
              name="name"
              value={fileData.name}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="File Name"
            />
            {error.name && (
              <div className="text-red-500 text-sm mt-1">{error.name}</div>
            )}
          </div>

          {/* File Type */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              File Type
            </label>
            <select
              name="file_type"
              value={fileData.file_type}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <option value="canva">Canva</option>
              <option value="link">Link</option>
            </select>
          </div>

          {/* File URL */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              File URL
            </label>
            <input
              type="url"
              name="file_url"
              value={fileData.file_url}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="https://example.com/file"
            />
            {error.file_url && (
              <div className="text-red-500 text-sm mt-1">{error.file_url}</div>
            )}
          </div>

          {/* Public Toggle */}
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              name="is_public"
              checked={fileData.is_public}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label className="text-sm text-gray-700">Make Public</label>
          </div>

          <div className="mt-4 flex space-x-4">
            <button
              role="button"
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Save
            </button>
            <button
              role="button"
              onClick={handleCancelEdit}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-2">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h5 className="text-xl font-bold leading-none text-gray-900">
                {fileData.name}
              </h5>
              <Badge
                status={fileData.is_public ? "Public" : "Private"}
                color={fileData.is_public ? "green" : "gray"}
              />
            </div>

            <div className="relative">
              <button
                onClick={() => setShowOptions(!showOptions)}
                className="p-1 rounded hover:bg-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {showOptions && (
                <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg">
                  <button
                    onClick={handleEditToggle}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Edit
                  </button>
                  <button
                    onClick={handleDelete}
                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
          <p>
            <span className="block text-gray-500">File URL:</span>{" "}
            <a
              href={fileData?.file_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              {fileData?.file_url || "---"}
            </a>
          </p>
          {fileData?.file_url && fileData?.file_type == "canva" && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "0",
                paddingTop: "56.25%",
                paddingBottom: "0",
                boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
                marginTop: "1.6em",
                marginBottom: "0.9em",
                overflow: "hidden",
                borderRadius: "8px",
                willChange: "transform",
              }}
            >
              <iframe
                loading="lazy"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0",
                  left: "0",
                  border: "none",
                  padding: "0",
                  margin: "0",
                }}
                src={`${fileData.file_url}?embed`}
                allowFullScreen
                allow="fullscreen"
                title="File"
              ></iframe>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileDetails;
