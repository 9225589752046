import { Link, useParams } from "react-router-dom";
import { decryptToken } from "../../utils/cryptoUtils";
import ProjectHeader from "../../components/layout/projectLayout/ProjectHeader";
import ProjectFooter from "../../components/layout/projectLayout/ProjectFooter";
import { usePublicProjectDetails } from "../../hooks/usePublic/usePublic";
import LoadingPage from "../../components/common/empty/LoadingPage";

const SharedProject = () => {
  const { token } = useParams();
  const data = decryptToken(token);

  const { company_id, project_id } = data;

  // Fetch public project details
  const {
    data: projectDetails,
    isLoading,
    isError,
    error,
  } = usePublicProjectDetails(company_id, project_id);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError || !data || !data.company_id || !data.project_id) {
    return (
      <div className="bg-gray-50 h-screen flex items-center justify-center">
        <div className="text-center max-w-xl">
          <Link to="/" className="flex items-center mb-8">
            <span className="mx-auto text-3xl font-semibold whitespace-nowrap text-slate-500">
              {"Plura.design"}
            </span>
          </Link>
          <h1 className="text-3xl font-bold text-gray-900">
            Something went wrong or you do not have access to this project.
          </h1>
          <p className="mt-8 text-lg text-gray-600">
            Please contact the project administrator if you believe this is an
            error.
          </p>
        </div>
      </div>
    );
  }

  const { project, company, clients, public_files } = projectDetails || {};

  return (
    <div>
      <ProjectHeader data={company} />
      <HeroSection data={project} />
      <FilesContainer data={public_files} />
      <ProjectFooter data={company} />
    </div>
  );
};

export default SharedProject;

const HeroSection = ({ data }) => {
  return (
    <div className="bg-white">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        {/**
         * <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
        </div>
         */}
        <div className="mx-auto max-w-2xl mb-24">
          {data?.status && (
            <div className="mb-8 flex justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10">
                Status: {data?.status}
              </div>
            </div>
          )}
          <div className="text-center">
            <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900">
              {data?.name}
            </h1>
            {data?.description && (
              <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                {data?.description}
              </p>
            )}
          </div>
        </div>
        {/**
           * <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"></div>
        </div>
           */}
      </div>
    </div>
  );
};

const FilesContainer = ({ data = [] }) => {
  // Helper function to capitalize the first letter of file_type
  const capitalize = (str) => {
    if (!str) return "Unknown Type";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Separate the links and non-link files
  const links = data.filter((file) => file?.file_type === "link");
  const otherFiles = data.filter((file) => file?.file_type !== "link");

  return (
    <div>
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-2">
          {otherFiles?.length > 0 ? (
            otherFiles.map((file) => (
              <div key={file?.id} className="relative lg:row-span-2">
                <div className="absolute inset-px rounded-lg bg-white lg:rounded-[2rem]"></div>
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-[calc(2rem+1px)]">
                  <div className="px-8 pb-3 pt-8">
                    <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                      {file?.name || "Unnamed File"}
                    </p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                      {capitalize(file?.file_type)}
                    </p>
                  </div>

                  {file?.file_url ? (
                    <div className="px-8 pb-8">
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "0",
                          paddingTop: "56.2%",
                          overflow: "hidden",
                          borderRadius: "8px",
                          willChange: "transform",
                        }}
                      >
                        <iframe
                          loading="lazy"
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: "0",
                            left: "0",
                            border: "none",
                            padding: "0",
                            margin: "0",
                          }}
                          src={`${file?.file_url}?embed`}
                          allowFullScreen
                          allow="fullscreen"
                          title={file?.name || "File Viewer"}
                        ></iframe>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-[2rem]"></div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center col-span-2">
              <div className="max-w-xl w-full border-2 border-dashed border-slate-300 rounded-lg bg-slate-50 p-10 flex flex-col items-center justify-center text-center">
                <h2 className="text-xl font-bold text-gray-800 mb-4">
                  No Files Added
                </h2>
                <p className="text-base text-gray-600 leading-relaxed">
                  No files have been added to your client dashboard. Contact
                  your studio design team to add files if needed.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Display links in a separate section */}
        {links.length > 0 && (
          <div className="mt-10">
            <h3 className="text-xl font-bold text-gray-800 mb-4">Links</h3>
            <div className="grid gap-4">
              {links?.map((file) => (
                <Link
                  key={file?.id}
                  to={file?.file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="shadow border border-gray-200 inline-flex items-center justify-center p-5 text-base font-medium text-gray-800 rounded-lg bg-white hover:text-gray-900 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-6 mr-2"
                  >
                    <path
                      fillRule="evenodd"
                      d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="w-full">{file?.name || "View Link"}</span>
                  <svg
                    className="w-4 h-4 ms-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
