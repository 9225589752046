import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WhatsAppButton from "../../../common/buttons/WhatsAppButton";
import management from "../../../../assets/images/management.png";
import tradePortal from "../../../../assets/images/tradePortal.png";
import finance from "../../../../assets/images/finance.png";
import clients from "../../../../assets/images/clients.png";

const Features = ({ isEarlyAccess }) => {
  const { t } = useTranslation();

  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="max-w-screen-md mb-8 lg:mb-16 text-center mx-auto">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
            {t("features.heading", "Así es Como Funciona...")}
          </h2>
          <p className="text-gray-500 sm:text-xl">
            {t(
              "features.subheading",
              "Simplifica tu proceso de diseño con herramientas intuitivas y efectivas."
            )}
          </p>
        </div>
        <FeatureDetail
          isEarlyAccess={isEarlyAccess}
          title={t("features.projectManagement.title", "Gestión de Proyectos")}
          isList={true}
          description={[
            t(
              "features.projectManagement.description1",
              "Cada proyecto de diseño tiene un portal propio para comunicarse con el cliente y llevar un registro detallado del proceso."
            ),
            t(
              "features.projectManagement.description2",
              "Puedes tener a la vista el estado de tus proyectos para mejorar tu organización y cumplimiento."
            ),
            t(
              "features.projectManagement.description3",
              "Tu equipo siempre estará al tanto de la etapa en la que se encuentra el proyecto, asegurando una coordinación fluida."
            ),
          ]}
          ctaText={t("features.ctaText", "Get Started Free")}
          imageUrl={management}
          link={"/register"}
        />
        <FeatureDetail
          isEarlyAccess={isEarlyAccess}
          title={t(
            "features.budgetManagement.title",
            "Gestión de Presupuestos"
          )}
          isList={true}
          description={[
            t(
              "features.budgetManagement.description1",
              "En un solo lugar le puedes dar seguimiento a todos los proveedores involucrados en tus proyectos."
            ),
            t(
              "features.budgetManagement.description2",
              "Actualización en tiempo real de la rentabilidad de cada proyecto."
            ),
            t(
              "features.budgetManagement.description3",
              "Una visión global de tus ganancias, flujo de caja y gastos."
            ),
          ]}
          ctaText={t("features.ctaText", "Get Started Free")}
          imageUrl={finance}
          reverse
          link={"/register"}
        />
        <FeatureDetail
          isEarlyAccess={isEarlyAccess}
          title={t("features.clientPanel.title", "Panel de Clientes")}
          description={t(
            "features.clientPanel.description",
            "Espacios interactivos y personalizados para compartir actualizaciones de proyectos, conceptos de diseño, cronogramas y cotizaciones."
          )}
          ctaText={t("features.ctaText", "Get Started Free")}
          imageUrl={clients}
          link={"/register"}
        />
        <FeatureDetail
          isEarlyAccess={isEarlyAccess}
          title={t(
            "features.supplierDirectory.title",
            "Directorio de Proveedores"
          )}
          description={t(
            "features.supplierDirectory.description",
            "¿Varado con tus cotizaciones porque no tienes proveedores confiables? Acá tendrás un amplio directorio especializado para cada uno de tus proyectos."
          )}
          ctaText={t("features.ctaText", "Get Started Free")}
          imageUrl={tradePortal}
          reverse
          link={"/register"}
        />
      </div>
    </section>
  );
};

export default Features;

const FeatureDetail = ({
  isEarlyAccess,
  title,
  isList = false,
  description,
  ctaText,
  imageUrl,
  reverse,
  link,
}) => {
  return (
    <div
      className={`gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6 md:grid md:grid-cols-2`}
    >
      <div className={`w-full ${reverse ? "md:order-2" : ""}`}>
        <img
          className="w-full rounded shadow-md border border-slate-200"
          src={imageUrl}
          alt="features"
        />
      </div>
      <div className={`mt-4 md:mt-0 ${reverse ? "md:order-1" : ""}`}>
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
          {title}
        </h2>
        {isList ? (
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 mb-6">
            {description?.map((val, i) => (
              <li className="flex font-light text-gray-500 md:text-lg" key={i}>
                <svg
                  className="size-4 mt-1.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                {val}
              </li>
            ))}
          </ul>
        ) : (
          <p className="mb-6 font-light text-gray-500 md:text-lg">
            {description}
          </p>
        )}

        {isEarlyAccess ? (
          <WhatsAppButton isFeature />
        ) : (
          <Link
            to={link}
            className="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            {ctaText}
            <svg
              className="ml-2 -mr-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        )}
      </div>
    </div>
  );
};
