import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { useProjects } from "../../../../hooks/useProjects/useProjects";
import { useProviders } from "../../../../hooks/useProviders/useProviders";

const EditPurchaseOrder = ({
  updateExpense,
  expenseData,
  handleEditToggle,
  onExpenseUpdate,
}) => {
  const userLocale = navigator.language || "es-CO";
  const userCurrency = "COP";

  // Set up initial form state
  const [formData, setFormData] = useState({
    description: expenseData?.description || "",
    amount: expenseData?.amount || 0,
    due_date: expenseData?.due_date ? expenseData.due_date.split("T")[0] : "",
    status: expenseData?.status || "Pending",
    location: expenseData?.location || "",
    payment_type: expenseData?.payment_type || "Credit Card",
    items: expenseData?.items || {},
    project_id: expenseData?.project_id || "",
    provider_id: expenseData?.provider_id || "",
  });
  const [error, setError] = useState({});

  // Fetch projects and providers for the AsyncSelect components
  const { data: projectsData } = useProjects(1, 10000);
  const { data: providersData } = useProviders(1, 10000);

  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];
  const providerOptions =
    providersData?.providers.map((provider) => ({
      value: provider.id,
      label: provider.company_name,
    })) || [];

  const formatCurrency = (value) =>
    new Intl.NumberFormat(userLocale, {
      currency: userCurrency,
      maximumFractionDigits: 0,
    }).format(value);

  const calculateTotalAmount = (items) =>
    Object.values(items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) * (parseInt(item.quantity, 10) || 1) || 0),
      0
    );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle changes to an item within the items collection.
  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? Math.max(0, parseFloat(value) || 0)
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };

      return {
        ...prevData,
        items: updatedItems,
        amount: calculateTotalAmount(updatedItems),
      };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: {
          name: "",
          description: "",
          quantity: 1,
          value: 0,
        },
      },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];
      return {
        ...prevData,
        items: updatedItems,
        amount: calculateTotalAmount(updatedItems),
      };
    });
  };

  const validateInputs = () => {
    const errors = {};

    if (!formData.description.trim())
      errors.description = "Description is required.";

    if (formData.amount <= 0)
      errors.amount = "Total amount must be greater than 0.";

    Object.keys(formData.items).forEach((itemId) => {
      const item = formData.items[itemId];
      if (!item.name.trim())
        errors[`itemName${itemId}`] = "Item name is required.";
      if (!item.quantity || isNaN(item.quantity) || item.quantity <= 0)
        errors[`itemQuantity${itemId}`] = "Quantity must be greater than 0.";
      if (!item.value || isNaN(item.value) || item.value <= 0)
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
    });

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    if (!validateInputs()) return;

    try {
      const cleanedData = {
        ...formData,
        amount: calculateTotalAmount(formData.items),
        items: Object.fromEntries(
          Object.entries(formData.items).map(([key, item]) => [
            key,
            {
              ...item,
              value: parseFloat(item.value),
              quantity: parseInt(item.quantity, 10),
            },
          ])
        ),
      };

      await updateExpense.mutateAsync({
        expenseId: expenseData.id,
        data: cleanedData,
      });
      onExpenseUpdate(cleanedData);
      handleEditToggle();
    } catch (error) {
      setError({ submit: "Failed to update expense. Please try again." });
    }
  };

  return (
    <div>
      {error.submit && (
        <div className="text-red-500 p-4 mb-4 bg-red-50 rounded">
          {error.submit}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Description */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            className="border p-2 w-full rounded max-w-md"
          />
          {error.description && (
            <div className="text-red-500 text-sm mt-1">{error.description}</div>
          )}
        </div>

        {/* Due Date */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Due Date
          </label>
          <input
            type="date"
            name="due_date"
            value={formData.due_date}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          />
        </div>

        {/* Status */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
            className="border p-2 w-full rounded max-w-md"
          >
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="In Process">In Process</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        {/* Payment Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Payment Type
          </label>
          <select
            name="payment_type"
            value={formData.payment_type}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          >
            <option value="Credit Card">Credit Card</option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Cash">Cash</option>
            <option value="Check">Check</option>
            <option value="Wire Transfer">Wire Transfer</option>
            <option value="Mobile Payment">Mobile Payment</option>
            <option value="Digital Wallet">Digital Wallet</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Location */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Location
          </label>
          <input
            type="text"
            name="location"
            placeholder="Expense location (optional)"
            value={formData.location}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          />
        </div>

        {/* Project */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Project (optional)
          </label>
          <AsyncSelect
            className="max-w-md"
            cacheOptions
            defaultOptions={projectOptions}
            loadOptions={(inputValue, callback) =>
              callback(
                projectOptions.filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                )
              )
            }
            onChange={(selected) =>
              setFormData((prevData) => ({
                ...prevData,
                project_id: selected ? selected.value : "",
              }))
            }
            placeholder="Select a project"
            value={
              projectOptions.find(
                (option) => option.value === formData.project_id
              ) || null
            }
          />
        </div>

        {/* Provider */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Provider (optional)
          </label>
          <AsyncSelect
            className="max-w-md"
            cacheOptions
            defaultOptions={providerOptions}
            loadOptions={(inputValue, callback) =>
              callback(
                providerOptions.filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                )
              )
            }
            onChange={(selected) =>
              setFormData((prevData) => ({
                ...prevData,
                provider_id: selected ? selected.value : "",
              }))
            }
            placeholder="Select a provider"
            value={
              providerOptions.find(
                (option) => option.value === formData.provider_id
              ) || null
            }
          />
        </div>

        {/* Items Section */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Items
          </label>
          {/* Responsive Wrapper */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="bg-gray-100 text-sm font-medium text-gray-700">
                <tr>
                  <th className="px-4 py-2">Item Name</th>
                  <th className="px-4 py-2">Quantity</th>
                  <th className="px-4 py-2">Price</th>
                  <th className="px-4 py-2"></th>
                </tr>
              </thead>
              <tbody>
                {Object?.entries(formData?.items)?.map(([itemId, item]) => (
                  <tr
                    key={itemId}
                    className="text-sm text-gray-700 border-b border-gray-200"
                  >
                    {/* Item Name and Description */}
                    <td className="px-4 py-2 align-top">
                      <input
                        type="text"
                        placeholder="Item Name"
                        value={item.name}
                        onChange={(e) =>
                          handleItemChange(itemId, "name", e.target.value)
                        }
                        className="border p-2 w-full min-w-[170px] rounded mb-1"
                      />
                      {error[`itemName${itemId}`] && (
                        <div className="text-red-500 text-xs mb-2">
                          {error[`itemName${itemId}`]}
                        </div>
                      )}
                      <input
                        type="text"
                        placeholder="Description (Optional)"
                        value={item.description}
                        onChange={(e) =>
                          handleItemChange(
                            itemId,
                            "description",
                            e.target.value
                          )
                        }
                        className="border p-2 w-full min-w-[170px] rounded"
                      />
                    </td>
                    {/* Quantity */}
                    <td className="px-4 py-2 align-top">
                      <input
                        type="number"
                        placeholder="Quantity"
                        min="0"
                        value={item.quantity}
                        onChange={(e) =>
                          handleItemChange(itemId, "quantity", e.target.value)
                        }
                        className="border p-2 w-full min-w-[60px] rounded"
                      />
                      {error[`itemQuantity${itemId}`] && (
                        <div className="text-red-500 text-xs">
                          {error[`itemQuantity${itemId}`]}
                        </div>
                      )}
                    </td>
                    {/* Value (Price) */}
                    <td className="px-4 py-2 align-top">
                      <input
                        type="text"
                        placeholder="Price"
                        value={item.value ? formatCurrency(item.value) : ""}
                        onChange={(e) => {
                          const rawValue = e.target.value.replace(
                            /[^\d.]/g,
                            ""
                          );
                          if (rawValue === "" || isNaN(rawValue)) return;
                          handleItemChange(
                            itemId,
                            "value",
                            parseFloat(rawValue)
                          );
                        }}
                        onBlur={() => {
                          const numericValue = parseFloat(
                            item.value || 0
                          ).toFixed(0);
                          handleItemChange(itemId, "value", numericValue);
                        }}
                        className="border p-2 w-full min-w-[110px] rounded"
                      />

                      {error[`itemValue${itemId}`] && (
                        <div className="text-red-500 text-xs">
                          {error[`itemValue${itemId}`]}
                        </div>
                      )}
                    </td>
                    {/* Actions */}
                    <td className="px-4 py-2 align-top text-center">
                      <button
                        type="button"
                        onClick={() => removeItem(itemId)}
                        className="text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            type="button"
            onClick={addItem}
            className="mt-2 text-blue-500 hover:underline"
          >
            Add Item
          </button>
        </div>

        {/* Total Amount */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Total Amount
          </label>
          <input
            type="text"
            value={formatCurrency(formData.amount)}
            readOnly
            disabled
            className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
          />
        </div>

        {/* Form actions */}
        <div className="mt-4 flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={updateExpense.isLoading}
          >
            {updateExpense.isLoading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={handleEditToggle}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPurchaseOrder;
