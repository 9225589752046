import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import AsyncSelect from "react-select/async";
import { useCreateInvoice } from "../../../hooks/useInvoices/useInvoices";
import { useProjects } from "../../../hooks/useProjects/useProjects";
import { useClients } from "../../../hooks/useClients/useClients";
import "react-phone-input-2/lib/style.css";

const InvoiceItems = ({
  items,
  errors,
  onItemChange,
  onAddItem,
  onRemoveItem,
}) => {
  const userLocale = navigator.language || "en-US";
  const userCurrency = "COP";

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Items
      </label>
      {/* Responsive Wrapper */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead className="bg-gray-100 text-sm font-medium text-gray-700">
            <tr>
              <th className="px-4 py-2">Item Name</th>
              <th className="px-4 py-2">Quantity</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2"></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(items).map(([itemId, item]) => (
              <tr
                key={itemId}
                className="text-sm text-gray-700 border-b border-gray-200"
              >
                {/* Item Name and Description */}
                <td className="px-4 py-2 align-top">
                  <input
                    type="text"
                    placeholder="Item Name"
                    value={item.name}
                    onChange={(e) =>
                      onItemChange(itemId, "name", e.target.value)
                    }
                    className="border p-2 w-full min-w-[170px] rounded mb-1"
                  />
                  {errors[`itemName${itemId}`] && (
                    <div className="text-red-500 text-xs mb-2">
                      {errors[`itemName${itemId}`]}
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder="Description (Optional)"
                    value={item.description || ""}
                    onChange={(e) =>
                      onItemChange(itemId, "description", e.target.value)
                    }
                    className="border p-2 w-full min-w-[170px] rounded"
                  />
                </td>
                {/* Quantity */}
                <td className="px-4 py-2 align-top">
                  <input
                    type="number"
                    placeholder="Quantity"
                    min="0"
                    value={item.quantity || ""}
                    onChange={(e) =>
                      onItemChange(itemId, "quantity", e.target.value)
                    }
                    className="border p-2 w-full min-w-[60px] rounded"
                  />
                  {errors[`itemQuantity${itemId}`] && (
                    <div className="text-red-500 text-xs">
                      {errors[`itemQuantity${itemId}`]}
                    </div>
                  )}
                </td>
                {/* Value (Price) */}
                <td className="px-4 py-2 align-top">
                  <input
                    type="text"
                    placeholder="Item Value"
                    value={
                      item.value !== undefined && item.value !== ""
                        ? new Intl.NumberFormat(userLocale, {
                            currency: userCurrency,
                            maximumFractionDigits: 0,
                          }).format(item.value)
                        : ""
                    }
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/[^\d.]/g, "");
                      if (rawValue === "" || isNaN(rawValue)) return;
                      onItemChange(itemId, "value", parseFloat(rawValue));
                    }}
                    onBlur={() => {
                      const numericValue = parseFloat(item.value || 0).toFixed(
                        0
                      );
                      onItemChange(itemId, "value", numericValue);
                    }}
                    className="border p-2 w-full rounded min-w-[110px]"
                  />
                  {errors[`itemValue${itemId}`] && (
                    <div className="text-red-500 text-xs">
                      {errors[`itemValue${itemId}`]}
                    </div>
                  )}
                </td>
                {/* Actions */}
                <td className="px-4 py-2 align-top text-center">
                  <button
                    type="button"
                    onClick={() => onRemoveItem(itemId)}
                    className="text-red-500 hover:underline"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        onClick={onAddItem}
        className="mt-2 text-blue-500 hover:underline"
      >
        Add Item
      </button>
    </div>
  );
};

const CreateInvoice = () => {
  const location = useLocation();
  const paramProjectId = new URLSearchParams(location.search).get("projectId");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    items: {},
    total_amount: 0,
    discount_type: "percentage",
    discount_value: 0,
    final_amount: 0,
    due_date: "",
    status: "Pending",
    notes: "",
    terms: "",
    project_id: paramProjectId || "",
    client_id: "",
  });

  const [error, setError] = useState({});
  const createInvoice = useCreateInvoice();

  // Fetch projects and clients
  const { data: projectsData } = useProjects(1, 10000);
  const { data: clientsData } = useClients(1, 10000);

  // Prepare options for dropdowns.
  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];
  const clientOptions =
    clientsData?.clients.map((client) => ({
      value: client.id,
      label: client.name,
    })) || [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      const totalAmount = updatedData.total_amount || 0;
      const discountValue = parseFloat(updatedData.discount_value) || 0;
      let finalAmount = totalAmount;
      if (updatedData.discount_type === "percentage") {
        finalAmount = totalAmount - (totalAmount * discountValue) / 100;
      } else if (updatedData.discount_type === "value") {
        finalAmount = totalAmount - discountValue;
      }
      finalAmount = Math.max(finalAmount, 0);
      return { ...updatedData, final_amount: finalAmount };
    });
  };

  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? Math.max(1, parseFloat(value))
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };

      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) =>
          sum +
          (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
        0
      );

      const discountValue =
        prevData.discount_type === "percentage"
          ? (totalAmount * prevData.discount_value) / 100
          : parseFloat(prevData.discount_value) || 0;

      return {
        ...prevData,
        items: updatedItems,
        total_amount: totalAmount,
        final_amount: Math.max(totalAmount - discountValue, 0),
      };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: { name: "", quantity: 1, value: 0 },
      },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];

      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) =>
          sum +
          (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
        0
      );
      const discountValue =
        prevData.discount_type === "percentage"
          ? (totalAmount * prevData.discount_value) / 100
          : parseFloat(prevData.discount_value) || 0;

      return {
        ...prevData,
        items: updatedItems,
        total_amount: totalAmount,
        final_amount: Math.max(totalAmount - discountValue, 0),
      };
    });
  };

  const validateInputs = () => {
    const errors = {};
    if (!formData.due_date.trim()) errors.due_date = "Due date is required.";
    if (formData.total_amount <= 0)
      errors.total_amount = "Total amount must be greater than 0.";

    // Validate each invoice item
    Object.keys(formData.items).forEach((itemId) => {
      const item = formData.items[itemId];
      if (!item.name.trim())
        errors[`itemName${itemId}`] = "Item name is required.";
      if (!item.value || isNaN(item.value) || item.value <= 0)
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    // Recalculate the total amount based on updated items (value * quantity).
    const recalculatedTotal = Object.values(formData.items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );

    // Recalculate discount.
    const discountValue =
      formData.discount_type === "percentage"
        ? (recalculatedTotal * parseFloat(formData.discount_value)) / 100
        : parseFloat(formData.discount_value) || 0;

    // Update final amount.
    const recalculatedFinal = Math.max(recalculatedTotal - discountValue, 0);

    // Update formData with recalculated amounts.
    const updatedFormData = {
      ...formData,
      total_amount: recalculatedTotal,
      final_amount: recalculatedFinal,
    };

    console.log(updatedFormData);

    if (!validateInputs()) return;

    try {
      await createInvoice.mutateAsync(updatedFormData);
      const redirectUrl = new URLSearchParams(location.search).get("redirect")
        ? `${new URLSearchParams(location.search).get("redirect")}?view=finance`
        : `/invoices`;
      navigate(redirectUrl);
    } catch (err) {
      setError({ submit: "Failed to create invoice. Please try again." });
    }
  };

  return (
    <div>
      <PageHeadings title="Create Invoice" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {error.submit && (
            <div className="text-red-600 p-4 mb-4 bg-red-50 rounded">
              {error.submit}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Invoice Status */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Invoice Status
              </label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="Pending">Pending</option>
                <option value="Paid">Paid</option>
                <option value="Overdue">Overdue</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>

            {/* Due Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Due Date
              </label>
              <input
                type="date"
                name="due_date"
                value={formData.due_date}
                onChange={handleChange}
                required
                className="border p-2 w-full rounded max-w-md"
              />
              {error.due_date && (
                <div className="text-red-600 text-xs mt-1">
                  {error.due_date}
                </div>
              )}
            </div>

            {/* Client Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Client
              </label>
              <AsyncSelect
                className="max-w-md"
                cacheOptions
                required
                defaultOptions={clientOptions}
                loadOptions={(inputValue, callback) =>
                  callback(
                    clientOptions.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  )
                }
                onChange={(selected) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    client_id: selected ? selected.value : "",
                  }))
                }
                placeholder="Select a client"
              />
            </div>

            {/* Project Dropdown (if projectId not preset) */}
            {!paramProjectId && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Project (Optional)
                </label>
                <AsyncSelect
                  className="max-w-md"
                  cacheOptions
                  defaultOptions={projectOptions}
                  loadOptions={(inputValue, callback) =>
                    callback(
                      projectOptions.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    )
                  }
                  onChange={(selected) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      project_id: selected ? selected.value : "",
                    }))
                  }
                  placeholder="Select a project"
                />
              </div>
            )}

            {/* Items Section */}
            <InvoiceItems
              items={formData.items}
              errors={error}
              onItemChange={handleItemChange}
              onAddItem={addItem}
              onRemoveItem={removeItem}
            />

            {/* Total Amount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Total Amount
              </label>
              <input
                type="text"
                name="total_amount"
                value={
                  formData.total_amount
                    ? new Intl.NumberFormat().format(formData.total_amount)
                    : 0
                }
                readOnly
                disabled
                className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
              />
              {error.total_amount && (
                <div className="text-red-600 text-xs mt-1">
                  {error.total_amount}
                </div>
              )}
            </div>

            {/* Discount Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Discount Type
              </label>
              <select
                name="discount_type"
                value={formData.discount_type}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="percentage">Percentage</option>
                <option value="value">Value</option>
              </select>
            </div>

            {/* Discount Value */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Discount Value
              </label>
              <input
                type="number"
                name="discount_value"
                value={formData.discount_value}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* Final Amount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Final Amount
              </label>
              <input
                type="text"
                name="final_amount"
                value={
                  formData.final_amount
                    ? new Intl.NumberFormat().format(formData.final_amount)
                    : 0
                }
                readOnly
                disabled
                className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
              />
            </div>

            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded"
              disabled={createInvoice.isLoading}
            >
              {createInvoice.isLoading ? "Adding..." : "Add Invoice"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateInvoice;
