import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import axiosInstance from "../../hooks/axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch all providers with pagination
const fetchProviders = async (page, perPage, search, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/providers?page=${page}&per_page=${perPage}&search=${encodeURIComponent(
      search || ""
    )}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch a single provider by ID
const fetchProvider = async (providerId, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/providers/${providerId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch all providers hook
export const useProviders = (page = 1, perPage = 10, search = "") => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["providers", page, perPage, search],
    () => fetchProviders(page, perPage, search, user?.token),
    { enabled: !!user?.token }
  );
};

// Fetch single provider hook
export const useProvider = (providerId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["provider", providerId],
    () => fetchProvider(providerId, user?.token),
    { enabled: !!user?.token }
  );
};

// Create provider hook
export const useCreateProvider = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    (data) =>
      axiosInstance.post(`${API_URL}/providers`, data, {
        headers: { Authorization: `Bearer ${user?.token}` },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("providers"),
    }
  );
};

// Update provider hook
export const useUpdateProvider = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ providerId, data }) =>
      axiosInstance.put(`${API_URL}/providers/${providerId}`, data, {
        headers: { Authorization: `Bearer ${user?.token}` },
      }),
    {
      onSuccess: (data, variables) => {
        // Invalidate both providers and provider details cache to keep data fresh
        queryClient.invalidateQueries("providers");
        queryClient.invalidateQueries(["provider", variables.providerId]);
      },
    }
  );
};

// Delete provider hook
export const useDeleteProvider = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ providerId }) =>
      axiosInstance.delete(`${API_URL}/providers/${providerId}`, {
        headers: { Authorization: `Bearer ${user?.token}` },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("providers"),
    }
  );
};
