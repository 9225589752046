import React, { useState, useContext } from "react";
import Select from "react-select";
import { useUpdateTask } from "../../../../../../hooks/useTasks/useTasks";
import { useUsers } from "../../../../../../hooks/useUsers";
import { AuthContext } from "../../../../../../context/AuthProvider";
import { format } from "date-fns";
import { usePhases } from "../../../../../../hooks/useTasks/usePhases";

const TaskSidebar = ({ task, onClose }) => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;

  // Helper function to parse DB date -> "YYYY-MM-DD"
  const parseDate = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? "" : date.toISOString().split("T")[0];
    } catch {
      return "";
    }
  };

  // ----------------------------
  // Local States
  // ----------------------------
  const [status, setStatus] = useState(task.status || "todos");
  const [assignedUsers, setAssignedUsers] = useState(task.users || []);
  const [startDate, setStartDate] = useState(parseDate(task?.initial_date));
  const [dueDate, setDueDate] = useState(parseDate(task?.end_date));
  const [error, setError] = useState("");

  // Track the selected phase ID
  const [selectedPhaseId, setSelectedPhaseId] = useState(task?.phase_id || "");

  // ----------------------------
  // Data Fetching (React Query)
  // ----------------------------
  const [page] = useState(1);
  const [perPage] = useState(100);

  // Users
  const { data: usersData, isLoading: isLoadingUsers } = useUsers(
    page,
    perPage
  );

  // Phases
  const {
    data: phasesData,
    isLoading: isPhasesLoading,
    isError: isPhasesError,
  } = usePhases(companyId);

  // Mutation to update task
  const { mutate: updateTask } = useUpdateTask();

  // ----------------------------
  // Handlers
  // ----------------------------
  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    updateTask({ taskId: task.id, data: { status: newStatus } });
  };

  const handleAssignUsers = (selectedOptions) => {
    const userIds = selectedOptions?.map((option) => option.value) || [];
    const updatedUsers =
      selectedOptions?.map((option) => ({
        id: option.value,
        username: option.label,
      })) || [];
    setAssignedUsers(updatedUsers);
    updateTask({ taskId: task.id, data: { users: userIds } });
  };

  // Start Date
  const handleStartDateChange = (newDate) => {
    setError("");
    if (!newDate) {
      setStartDate("");
      updateTask({ taskId: task.id, data: { initial_date: "" } });
      return;
    }
    const newDateObj = new Date(newDate);
    if (isNaN(newDateObj.getTime())) {
      setError("Invalid start date!");
      return;
    }
    // Check vs dueDate
    if (dueDate) {
      const dueDateObj = new Date(dueDate);
      if (newDateObj > dueDateObj) {
        setError("Start date cannot be after the due date!");
        return;
      }
    }
    setStartDate(newDate);
    updateTask({
      taskId: task.id,
      data: { initial_date: newDateObj.toISOString() },
    });
  };

  // Due Date
  const handleDueDateChange = (newDate) => {
    setError("");
    if (!newDate) {
      setDueDate("");
      updateTask({ taskId: task.id, data: { end_date: "" } });
      return;
    }
    const newDateObj = new Date(newDate);
    if (isNaN(newDateObj.getTime())) {
      setError("Invalid due date!");
      return;
    }
    // Check vs startDate
    if (startDate) {
      const startDateObj = new Date(startDate);
      if (startDateObj > newDateObj) {
        setError("Due date cannot be before the start date!");
        return;
      }
    }
    setDueDate(newDate);
    updateTask({
      taskId: task.id,
      data: { end_date: newDateObj.toISOString() },
    });
  };

  // Phase selection/change handler
  const handlePhaseChange = (selectedOption) => {
    if (!selectedOption || selectedOption.value === "") {
      setSelectedPhaseId("");
      updateTask({ taskId: task.id, data: { phase_id: null } });
    } else {
      setSelectedPhaseId(selectedOption.value);
      updateTask({ taskId: task.id, data: { phase_id: selectedOption.value } });
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return format(new Date(dateString), "MMM d, yyyy");
    } catch {
      return "Invalid Date";
    }
  };

  // ----------------------------
  // Convert data to React-Select options
  // ----------------------------
  // Add a default option for "Select Phase..."
  const defaultOption = { value: "", label: "Select Phase..." };

  // Map phases
  const actualPhaseOptions =
    phasesData?.map((phase) => ({
      value: phase.id,
      label: phase.name,
    })) || [];

  // Combine default + real phases
  const phaseOptions = actualPhaseOptions?.length
    ? [defaultOption, ...actualPhaseOptions]
    : [];

  // Determine which option is selected
  const selectedPhaseOption =
    phaseOptions?.find((opt) => opt.value === selectedPhaseId) || defaultOption;

  // For assigned users
  const userOptions =
    usersData?.users?.map((user) => ({
      value: user.id,
      label: user.username,
    })) || [];

  const assignedOptions =
    assignedUsers?.map((user) => ({ value: user.id, label: user.username })) ||
    [];

  return (
    <div className="w-full md:max-w-xs bg-gray-50 rounded-md p-3 space-y-3 min-h-96 text-sm">
      <div className="flex justify-end">
        <button
          onClick={onClose}
          className="hidden md:block text-gray-500 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {/* Status */}
      <div>
        <label className="block font-semibold mb-1">Status</label>
        <select
          value={status}
          onChange={(e) => handleStatusChange(e.target.value)}
          className="w-full rounded border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
          <option value="todos">To Do</option>
          <option value="inProgress">In Progress</option>
          <option value="inReview">In Review</option>
          <option value="completed">Completed</option>
        </select>
      </div>

      {/* Phase */}
      <div>
        <label className="block font-semibold mb-1">Phase</label>
        <Select
          isSearchable
          placeholder="Select a phase..."
          isLoading={isPhasesLoading}
          options={phaseOptions}
          value={selectedPhaseOption}
          onChange={handlePhaseChange}
          className="w-full"
        />
        {isPhasesError && (
          <p className="text-xs text-red-600 mt-1">
            Error loading phases. Please try again.
          </p>
        )}
      </div>

      {/* Assigned Users */}
      <div>
        <label className="block font-semibold mb-1">Assigned Users</label>
        <Select
          isMulti
          isClearable={false}
          options={userOptions}
          value={assignedOptions}
          onChange={handleAssignUsers}
          isLoading={isLoadingUsers}
          placeholder="Select users..."
          className="w-full"
        />
      </div>

      {/* Start Date */}
      <div>
        <label className="block font-semibold mb-1">Start Date</label>
        <input
          type="date"
          value={startDate || ""}
          onChange={(e) => handleStartDateChange(e.target.value)}
          className="w-full rounded border border-gray-300 p-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      </div>

      {/* Due Date */}
      <div>
        <label className="block font-semibold mb-1">Due Date</label>
        <input
          type="date"
          value={dueDate || ""}
          onChange={(e) => handleDueDateChange(e.target.value)}
          className="w-full rounded border border-gray-300 p-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      </div>

      {/* Error Message */}
      {error && <div className="text-xs text-red-500">{error}</div>}

      {/* Dates Info */}
      <div>
        <label className="block font-semibold mb-1">Dates</label>
        <p className="text-xs text-gray-500">
          Created: {formatDate(task.creation_date)}
        </p>
        <p className="text-xs text-gray-500">
          Last Modified: {formatDate(task.last_modify)}
        </p>
      </div>
    </div>
  );
};

export default TaskSidebar;
