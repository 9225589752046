import React from "react";
import { NavLink } from "react-router-dom";

const MainStats = ({ data, companyInfo }) => {
  return (
    <>
      <StatCard
        title="Total Projects"
        value={data?.pagination?.total_items || 0}
      />
      <PlanCard tier={companyInfo?.tier} />
    </>
  );
};

const StatCard = ({ title, value }) => {
  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-1">
      <div className="flex items-center">
        <div className="ml-4">
          <h3 className="text-md font-semibold text-gray-700">{title}</h3>
          <p className="text-2xl font-bold text-gray-900">{value}</p>
        </div>
      </div>
    </div>
  );
};

const PlanCard = ({ tier }) => {
  let planName, cta;

  switch (tier) {
    case "1":
      planName = "Pro";
      break;
    case "2":
      planName = "Enterprise";
      break;
    case "0":
    default:
      planName = "Free";
      cta = (
        <NavLink
          className="ml-auto px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          to={"/settings?tab=Billing"}
        >
          Upgrade Now
        </NavLink>
      );
  }

  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-blue-50 border border-blue-300 p-6 col-span-1">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-md font-semibold text-gray-700">Current Plan</h3>
          <p className="text-2xl font-bold text-gray-900">{planName}</p>
        </div>
        {cta}
      </div>
    </div>
  );
};

export default MainStats;
