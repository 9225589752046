import React, { useState } from "react";
import { useUpdateTask } from "../../../../../../hooks/useTasks/useTasks";

const TaskDescription = ({ description, taskId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState(description);
  const { mutate: updateTask } = useUpdateTask();

  const handleSave = () => {
    setIsEditing(false);
    updateTask({ taskId, data: { description: newDescription } });
  };

  return (
    <div className="my-3">
      <h3 className="text-base font-semibold mb-1">Description</h3>
      {isEditing ? (
        <div>
          <textarea
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            className="w-full rounded border border-gray-300 p-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            rows={3}
          />
          <div className="mt-1 flex items-center gap-2">
            <button
              onClick={handleSave}
              className="rounded bg-blue-500 px-3 py-1 text-white text-sm hover:bg-blue-600"
            >
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="text-xs text-gray-500 hover:text-gray-700"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <p
          className="text-sm text-gray-700 cursor-pointer"
          onClick={() => setIsEditing(true)}
        >
          {newDescription || "No description available."}
        </p>
      )}
    </div>
  );
};

export default TaskDescription;
