import React from "react";
import LandingHeader from "../components/layout/landingLayout/LandingHeader";
import LandingFooter from "../components/layout/landingLayout/LandingFooter";
import Hero from "../components/layout/landingLayout/LandingSections/Hero";
import Quote from "../components/layout/landingLayout/LandingSections/Quote";
import Features from "../components/layout/landingLayout/LandingSections/Features";
import Pricing from "../components/layout/landingLayout/LandingSections/Pricing";

const IS_EARLY_ACCESS = true;

const Landing = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <LandingHeader isEarlyAccess={IS_EARLY_ACCESS} />

      {/* Main Content */}
      <main className="flex-grow">
        <Hero isEarlyAccess={IS_EARLY_ACCESS} />
        <Quote />
        <Features isEarlyAccess={IS_EARLY_ACCESS} />
        {!IS_EARLY_ACCESS && <Pricing />}
      </main>

      {/* Footer */}
      <LandingFooter />
    </div>
  );
};

export default Landing;
