import React, { useState } from "react";
import {
  useCreateSubtask,
  useDeleteSubtask,
  useSubtasksOfTask,
  useUpdateSubtask,
} from "../../../../../../hooks/useTasks/useSubtasks";

const TaskSubtasks = ({ taskId }) => {
  const { data: subtasks = [], refetch } = useSubtasksOfTask(taskId);
  const { mutate: createSubtask } = useCreateSubtask();
  const { mutate: updateSubtask } = useUpdateSubtask();
  const { mutate: deleteSubtask } = useDeleteSubtask();

  const [newSubtask, setNewSubtask] = useState("");
  const [editingSubtaskId, setEditingSubtaskId] = useState(null);
  const [editedText, setEditedText] = useState("");

  // Add a new subtask
  const handleAddSubtask = () => {
    if (newSubtask.trim()) {
      createSubtask(
        { task_id: taskId, title: newSubtask },
        { onSuccess: () => refetch() }
      );
      setNewSubtask("");
    }
  };

  // Toggle subtask completion
  const handleToggleSubtask = (subtask) => {
    updateSubtask(
      {
        subtaskId: subtask.id,
        data: { is_done: !subtask.is_done },
      },
      { onSuccess: () => refetch() }
    );
  };

  // Delete a subtask
  const handleDeleteSubtask = (subtaskId) => {
    deleteSubtask(subtaskId, {
      onSuccess: () => refetch(),
    });
  };

  // Start editing a subtask
  const handleEditSubtask = (subtask) => {
    setEditingSubtaskId(subtask.id);
    setEditedText(subtask.title);
  };

  // Save edited subtask text
  const handleSaveEditedSubtask = () => {
    if (editedText.trim() && editingSubtaskId) {
      updateSubtask(
        {
          subtaskId: editingSubtaskId,
          data: { title: editedText },
        },
        {
          onSuccess: () => {
            refetch();
            setEditingSubtaskId(null);
            setEditedText("");
          },
        }
      );
    } else {
      setEditingSubtaskId(null);
      setEditedText("");
    }
  };

  // Cancel editing
  const handleCancelEdit = () => {
    setEditingSubtaskId(null);
    setEditedText("");
  };

  return (
    <div className="my-3 text-sm">
      <h3 className="text-base font-semibold mb-2">Subtasks</h3>
      <div className="space-y-2">
        {subtasks.length > 0 ? (
          subtasks.map((subtask) => (
            <div
              key={subtask.id}
              className="flex items-center justify-between rounded bg-gray-50 border p-2"
            >
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={subtask.is_done}
                  onChange={() => handleToggleSubtask(subtask)}
                  className="h-4 w-4 rounded text-blue-600 focus:ring-blue-500"
                />
                {editingSubtaskId === subtask.id ? (
                  <input
                    type="text"
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    onBlur={handleSaveEditedSubtask}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleSaveEditedSubtask();
                      if (e.key === "Escape") handleCancelEdit();
                    }}
                    className="w-full border-b-2 border-blue-500 p-1 focus:outline-none"
                    autoFocus
                  />
                ) : (
                  <span
                    onClick={() => handleEditSubtask(subtask)}
                    className={`cursor-pointer ${
                      subtask.is_done
                        ? "line-through text-gray-400"
                        : "text-gray-800"
                    }`}
                  >
                    {subtask.title}
                  </span>
                )}
              </div>

              {/* Right side: Delete button */}
              <button
                onClick={() => handleDeleteSubtask(subtask.id)}
                className="text-red-500 hover:text-red-700"
              >
                Delete
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No subtasks yet.</p>
        )}
      </div>

      {/* Add New Subtask */}
      <div className="mt-2 flex items-center gap-2">
        <input
          type="text"
          value={newSubtask}
          onChange={(e) => setNewSubtask(e.target.value)}
          placeholder="Add new subtask..."
          className="flex-1 rounded border border-gray-300 p-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
        <button
          onClick={handleAddSubtask}
          className="rounded bg-blue-500 px-3 py-1 text-white hover:bg-blue-600"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default TaskSubtasks;
