import React, { useState } from "react";
import {
  useCommentsOfTask,
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from "../../../../../../hooks/useTasks/useComments";
import { getInitials } from "../../../../../../utils/helpers";

const TaskComments = ({ taskId }) => {
  // React Query hooks
  const { data: comments = [], refetch } = useCommentsOfTask(taskId);
  const { mutate: createComment } = useCreateComment();
  const { mutate: deleteComment } = useDeleteComment();
  const { mutate: updateComment } = useUpdateComment();

  // Local state
  const [newComment, setNewComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");

  // Format dates for display
  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(dateString));
  };

  // Handlers
  const handleAddComment = () => {
    if (newComment.trim()) {
      createComment(
        { task_id: taskId, content: newComment },
        { onSuccess: refetch }
      );
      setNewComment("");
    }
  };

  const handleEditComment = (comment) => {
    setEditingCommentId(comment.id);
    setEditedCommentText(comment.content);
  };

  const handleSaveEditedComment = () => {
    if (editedCommentText.trim() && editingCommentId) {
      updateComment(
        { commentId: editingCommentId, data: { content: editedCommentText } },
        {
          onSuccess: () => {
            refetch();
            resetEdit();
          },
        }
      );
    } else {
      resetEdit();
    }
  };

  const resetEdit = () => {
    setEditingCommentId(null);
    setEditedCommentText("");
  };

  const handleCancelEdit = () => resetEdit();

  const handleDeleteComment = (commentId) => {
    deleteComment(commentId, { onSuccess: refetch });
  };

  // Render
  return (
    <div className="my-4">
      <h3 className="text-base font-semibold mb-2">Comments</h3>

      {/* Comments List */}
      <div className="space-y-3">
        {comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id} className="text-sm">
              <div className="flex items-start gap-3">
                {/* Avatar */}
                <div
                  className="size-6 flex items-center justify-center rounded-full bg-blue-500 text-white"
                  style={{ fontSize: "10px" }}
                >
                  {getInitials(comment.user?.username || "U")}
                </div>

                {/* Main Content */}
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-1">
                    <p className="font-semibold text-gray-700">
                      {comment.user?.username || "Unknown User"}
                    </p>
                    <p className="text-xs text-gray-500">
                      {formatDate(comment.creation_date)}
                    </p>
                  </div>

                  {/* Editing a comment */}
                  {editingCommentId === comment.id ? (
                    <div>
                      <textarea
                        rows={2}
                        className="w-full rounded border border-gray-300 p-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                        value={editedCommentText}
                        onChange={(e) => setEditedCommentText(e.target.value)}
                      />
                      <div className="flex items-center gap-2 mt-1">
                        <button
                          onClick={handleSaveEditedComment}
                          className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          className="px-2 py-1 bg-gray-400 text-white rounded hover:bg-gray-500"
                        >
                          Discard
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="bg-gray-50 rounded px-2 py-1 text-gray-800 border border-gray-200">
                        {comment.content}
                      </p>
                      <div className="flex items-center gap-4 text-xs text-gray-500 mt-1">
                        <button
                          onClick={() => handleEditComment(comment)}
                          className="hover:text-gray-700"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteComment(comment.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-sm">No comments yet.</p>
        )}
      </div>

      {/* Add Comment */}
      <div className="mt-4">
        <textarea
          rows={2}
          className="w-full rounded border border-gray-300 p-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          placeholder="Add a comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <button
          onClick={handleAddComment}
          className="mt-2 px-3 py-1 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
        >
          Add Comment
        </button>
      </div>
    </div>
  );
};

export default TaskComments;
