import React from "react";

const TasksButtonGroupMenu = ({ viewMode, setViewMode }) => {
  return (
    <div className="inline-flex shadow-sm rounded-lg">
      <button
        type="button"
        className={`px-4 py-2 text-sm font-medium border border-gray-200 flex items-center justify-center ${
          viewMode === "board"
            ? "text-gray-900 bg-white"
            : "bg-gray-100 text-gray-900 hover:bg-white hover:text-blue-700"
        } rounded-s-lg`}
        onClick={() => setViewMode("board")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5 inline-block mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125Z"
          />
        </svg>
        Board
      </button>

      {/* List View Button */}
      <button
        type="button"
        className={`px-4 py-2 text-sm font-medium border border-gray-200 flex items-center justify-center ${
          viewMode === "list"
            ? "text-gray-900 bg-white"
            : "bg-gray-100 text-gray-900 hover:bg-white hover:text-blue-700"
        }`}
        onClick={() => setViewMode("list")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5 inline-block mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>
        List
      </button>

      {/* Gantt View Button */}
      <button
        type="button"
        className={`px-4 py-2 text-sm font-medium border border-gray-200 flex items-center justify-center ${
          viewMode === "gantt"
            ? "text-gray-900 bg-white"
            : "bg-gray-100 text-gray-900 hover:bg-white hover:text-blue-700"
        } rounded-e-lg`}
        onClick={() => setViewMode("gantt")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5 inline-block mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
          />
        </svg>
        Timeline
      </button>
    </div>
  );
};

export default TasksButtonGroupMenu;
