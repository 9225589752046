import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useUpdateInvoice } from "../../../../hooks/useInvoices/useInvoices";
import { useProjects } from "../../../../hooks/useProjects/useProjects";
import { useClients } from "../../../../hooks/useClients/useClients";

const EditInvoice = ({ invoiceData, onCancel, onSave }) => {
  // Fetch projects and clients
  const { data: projectsData } = useProjects(1, 10000);
  const { data: clientsData } = useClients(1, 10000);

  // Prepare options for dropdowns.
  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];
  const clientOptions =
    clientsData?.clients.map((client) => ({
      value: client.id,
      label: client.name,
    })) || [];

  const [formData, setFormData] = useState({
    ...invoiceData,
    items: invoiceData?.items || {},
  });
  const [error, setError] = useState({});
  const updateInvoice = useUpdateInvoice();

  useEffect(() => {
    setFormData({
      ...invoiceData,
      project_id: invoiceData?.project_id || "",
      client_id: invoiceData?.client_id || "",
      items: invoiceData?.items || {},
    });
  }, [invoiceData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      const totalAmount = updatedData.total_amount || 0;
      const discountValue = parseFloat(updatedData.discount_value) || 0;
      let finalAmount = totalAmount;

      if (updatedData.discount_type === "percentage") {
        finalAmount = totalAmount - (totalAmount * discountValue) / 100;
      } else if (updatedData.discount_type === "value") {
        finalAmount = totalAmount - discountValue;
      }
      finalAmount = Math.max(finalAmount, 0);
      return { ...updatedData, final_amount: finalAmount };
    });
  };

  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? Math.max(1, parseFloat(value))
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };

      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) =>
          sum +
          (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
        0
      );
      const discountValue =
        prevData.discount_type === "percentage"
          ? (totalAmount * prevData.discount_value) / 100
          : parseFloat(prevData.discount_value) || 0;

      return {
        ...prevData,
        items: updatedItems,
        total_amount: totalAmount,
        final_amount: Math.max(totalAmount - discountValue, 0),
      };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: {
          name: "",
          description: "",
          quantity: 1,
          value: 0,
        },
      },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];

      // Recalculate total and final amounts.
      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) =>
          sum +
          (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
        0
      );
      const discountValue =
        formData.discount_type === "percentage"
          ? (totalAmount * formData.discount_value) / 100
          : parseFloat(formData.discount_value) || 0;

      return {
        ...prevData,
        items: updatedItems,
        total_amount: totalAmount,
        final_amount: Math.max(totalAmount - discountValue, 0),
      };
    });
  };

  const validateInputs = () => {
    const errors = {};
    if (!formData.due_date?.trim()) errors.due_date = "Due date is required.";
    if (formData.total_amount <= 0)
      errors.total_amount = "Total amount must be greater than 0.";

    // Validate each invoice item.
    Object.keys(formData.items).forEach((itemId) => {
      const item = formData.items[itemId];
      if (!item.name.trim())
        errors[`itemName${itemId}`] = "Item name is required.";
      if (!item.value || isNaN(item.value) || item.value <= 0)
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    if (!validateInputs()) return;

    try {
      // Ensure each item has the correct numeric values.
      const cleanedData = {
        ...formData,
        items: Object.fromEntries(
          Object.entries(formData.items).map(([key, item]) => [
            key,
            {
              ...item,
              value: parseFloat(item.value),
              quantity: parseInt(item.quantity, 10),
            },
          ])
        ),
      };

      const updatedInvoice = await updateInvoice.mutateAsync({
        invoiceId: invoiceData.id,
        data: cleanedData,
      });
      onSave(updatedInvoice);
    } catch (err) {
      setError({ submit: "Failed to update invoice. Please try again." });
    }
  };

  return (
    <div>
      {error.submit && (
        <div className="text-red-500 p-4 mb-4 bg-red-50 rounded">
          {error.submit}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Invoice Status */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Invoice Status
          </label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          >
            <option value="Pending">Pending</option>
            <option value="Paid">Paid</option>
            <option value="Overdue">Overdue</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        {/* Due Date */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Due Date
          </label>
          <input
            type="date"
            name="due_date"
            value={formData?.due_date ? formData.due_date.split("T")[0] : ""}
            onChange={handleChange}
            required
            className="border p-2 w-full rounded max-w-md"
          />
          {error.due_date && (
            <div className="text-red-500 text-sm mt-1">{error.due_date}</div>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Client
          </label>
          <AsyncSelect
            className="max-w-md"
            cacheOptions
            defaultOptions={clientOptions}
            loadOptions={(inputValue, callback) =>
              callback(
                clientOptions.filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                )
              )
            }
            onChange={(selected) =>
              setFormData((prevData) => ({
                ...prevData,
                client_id: selected ? selected.value : "",
              }))
            }
            placeholder="Select a client"
            // IMPORTANT: Use `clientOptions` here to find the default value
            value={
              clientOptions.find(
                (option) => option.value === formData.client_id
              ) || null
            }
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Project (Optional)
          </label>
          <AsyncSelect
            className="max-w-md"
            cacheOptions
            defaultOptions={projectOptions}
            loadOptions={(inputValue, callback) =>
              callback(
                projectOptions.filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                )
              )
            }
            onChange={(selected) =>
              setFormData((prevData) => ({
                ...prevData,
                project_id: selected ? selected.value : "",
              }))
            }
            placeholder="Select a project"
            value={
              projectOptions.find(
                (option) => option.value === formData.project_id
              ) || null
            }
          />
        </div>

        {/* Invoice Items */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Items
          </label>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-gray-100">
                <tr className="text-sm">
                  <th className="px-4 py-2 text-left">Item Name</th>
                  <th className="px-4 py-2 text-left">Quantity</th>
                  <th className="px-4 py-2 text-left">Price</th>
                  <th className="px-4 py-2"></th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(formData.items).map(([itemId, item]) => (
                  <tr key={itemId} className="border-b">
                    {/* Item Name */}
                    <td className="px-4 py-2 align-top">
                      <input
                        type="text"
                        value={item.name}
                        onChange={(e) =>
                          handleItemChange(itemId, "name", e.target.value)
                        }
                        placeholder="Item Name"
                        className="border p-2 w-full rounded min-w-[180px]"
                      />
                      {error[`itemName${itemId}`] && (
                        <div className="text-red-500 text-xs mt-1">
                          {error[`itemName${itemId}`]}
                        </div>
                      )}
                      <input
                        type="text"
                        value={item.description || ""}
                        onChange={(e) =>
                          handleItemChange(
                            itemId,
                            "description",
                            e.target.value
                          )
                        }
                        placeholder="Description (Optional)"
                        className="border p-2 w-full rounded min-w-[180px] mt-2"
                      />
                    </td>

                    {/* Quantity */}
                    <td className="px-4 py-2 align-top">
                      <input
                        type="number"
                        min="1"
                        value={item.quantity || 1}
                        onChange={(e) =>
                          handleItemChange(itemId, "quantity", e.target.value)
                        }
                        className="border p-2 w-full rounded min-w-[60px]"
                      />
                    </td>
                    {/* Price / Value */}
                    <td className="px-4 py-2 align-top">
                      <input
                        type="number"
                        min="0"
                        value={item.value || 0}
                        onChange={(e) =>
                          handleItemChange(itemId, "value", e.target.value)
                        }
                        placeholder="Price"
                        className="border p-2 w-full rounded min-w-[110px]"
                      />
                      {error[`itemValue${itemId}`] && (
                        <div className="text-red-500 text-xs mt-1">
                          {error[`itemValue${itemId}`]}
                        </div>
                      )}
                    </td>
                    {/* Remove button */}
                    <td className="px-4 py-2 text-center">
                      <button
                        type="button"
                        onClick={() => removeItem(itemId)}
                        className="text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            type="button"
            onClick={addItem}
            className="mt-2 text-blue-500 hover:underline"
          >
            Add Item
          </button>
        </div>

        {/* Total Amount */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Total Amount
          </label>
          <input
            type="text"
            name="total_amount"
            value={
              formData.total_amount
                ? new Intl.NumberFormat().format(formData.total_amount)
                : 0
            }
            readOnly
            disabled
            className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
          />
        </div>

        {/* Discount Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Discount Type
          </label>
          <select
            name="discount_type"
            value={formData.discount_type}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          >
            <option value="percentage">Percentage</option>
            <option value="value">Value</option>
          </select>
        </div>

        {/* Discount Value */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Discount Value
          </label>
          <input
            type="number"
            name="discount_value"
            value={formData.discount_value}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          />
        </div>

        {/* Final Amount */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Final Amount
          </label>
          <input
            type="text"
            name="final_amount"
            value={
              formData.final_amount
                ? new Intl.NumberFormat().format(formData.final_amount)
                : 0
            }
            readOnly
            disabled
            className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
          />
        </div>

        <div className="flex space-x-4 mt-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={updateInvoice.isLoading}
          >
            {updateInvoice.isLoading ? "Saving..." : "Save Changes"}
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditInvoice;
