import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useDeleteClient,
  useUpdateClient,
} from "../../../hooks/useClients/useClients";
import Tabs from "../../../components/common/tabs/Tabs";
import ClientProjects from "./details/ClientProjects";
import ClientInvoices from "./details/ClientInvoices";
import ClientComments from "./details/ClientComments";
import Badge from "../../../components/common/badges/Badge";

const ClientDetails = ({ data, closeSidebar }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [clientData, setClientData] = useState(data);
  const [initialClientData, setInitialClientData] = useState(data);
  const [error, setError] = useState({});
  const [defaultCountry, setDefaultCountry] = useState("us");
  const navigate = useNavigate();

  const deleteClient = useDeleteClient();
  const updateClient = useUpdateClient();

  useEffect(() => {
    setClientData(data);
    setInitialClientData(data);

    const getDefaultCountry = () => {
      const locale = navigator.language || navigator.userLanguage;
      const country = locale.split("-")[1]?.toLowerCase();
      if (country) setDefaultCountry(country);
    };
    getDefaultCountry();
  }, [data]);

  const handleEditToggle = () => {
    setShowOptions(false);
    setIsEditing((prev) => !prev);
  };

  const handleDelete = async () => {
    setShowOptions(false);
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await deleteClient.mutateAsync({ clientId: clientData.id });
        closeSidebar();
        navigate("/clients");
      } catch (err) {
        setError((prevError) => ({
          ...prevError,
          submit: "Failed to delete client. Please try again.",
        }));
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setClientData((prevData) => ({ ...prevData, phone: value }));
  };

  const validateInputs = () => {
    const errors = {};
    // Client Name is required.
    if (!clientData.name.trim()) {
      errors.name = "Name is required.";
    }
    // Email is optional. Only validate if provided.
    if (clientData.email && !/^\S+@\S+\.\S+$/.test(clientData.email)) {
      errors.email = "Enter a valid email.";
    }
    if (!clientData.phone.trim()) {
      errors.phone = "Phone number is required.";
    }
    if (!clientData.status) {
      errors.status = "Status is required.";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateInputs()) return;
    try {
      await updateClient.mutateAsync({
        clientId: clientData.id,
        data: clientData,
      });
      setInitialClientData(clientData);
      setIsEditing(false);
    } catch (err) {
      setError((prevError) => ({
        ...prevError,
        submit: "Failed to update client. Please try again.",
      }));
    }
  };

  const handleCancelEdit = () => {
    setClientData(initialClientData);
    setIsEditing(false);
    setError({});
  };

  const tabs = [
    {
      label: "Projects",
      content: <ClientProjects data={clientData.projects} />,
    },
    {
      label: "Invoices",
      content: <ClientInvoices data={clientData.invoices} />,
    },
    {
      label: "Comments",
      content: <ClientComments clientId={clientData.id} />,
    },
  ];

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div
          className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg"
          role="alert"
        >
          {error.submit}
        </div>
      )}
      {isEditing ? (
        <div className="space-y-4">
          {/* Client Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Client Name
            </label>
            <input
              type="text"
              name="name"
              value={clientData.name}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Enter client name"
            />
            {error.name && (
              <div className="text-red-500 text-sm mt-1">{error.name}</div>
            )}
          </div>

          {/* Client Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Client Status
            </label>
            <select
              name="status"
              value={clientData.status}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <optgroup label="Prospect Statuses">
                <option value="Lead">Lead</option>
                <option value="Prospect">Prospect</option>
                <option value="Potential Client">Potential Client</option>
                <option value="Proposal Sent">Proposal Sent</option>
                <option value="Negotiation">Negotiation</option>
              </optgroup>
              <optgroup label="Client Statuses">
                <option value="Active Client">Active Client</option>
                <option value="In Progress">In Progress</option>
                <option value="Repeat Client">Repeat Client</option>
                <option value="High Priority">High Priority</option>
                <option value="On Hold">On Hold</option>
                <option value="Pending Payment">Pending Payment</option>
                <option value="Inactive">Inactive</option>
                <option value="Churned">Churned</option>
                <option value="Past Client">Past Client</option>
                <option value="VIP Client">VIP Client</option>
              </optgroup>
            </select>
            {error.status && (
              <div className="text-red-500 text-sm mt-1">{error.status}</div>
            )}
          </div>

          {/* Client Phone */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <PhoneInput
              country={defaultCountry}
              value={clientData.phone}
              onChange={handlePhoneChange}
              placeholder="Enter client phone number"
              inputClass="w-full border p-2 rounded"
              inputStyle={{ width: "100%" }}
              enableSearch={true}
            />
            {error.phone && (
              <div className="text-red-500 text-sm mt-1">{error.phone}</div>
            )}
          </div>

          {/* Client Email (Optional) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email (optional)
            </label>
            <input
              type="email"
              name="email"
              value={clientData.email}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Enter client email"
            />
            {error.email && (
              <div className="text-red-500 text-sm mt-1">{error.email}</div>
            )}
          </div>

          {/* Client Address */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Address (optional)
            </label>
            <input
              type="text"
              name="address"
              value={clientData.address}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Enter client address"
            />
          </div>

          {/* Action Buttons */}
          <div className="mt-4 flex space-x-4">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Save
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-2">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h5 className="text-xl font-bold text-gray-900">
                {clientData.name}
              </h5>
              <Badge status={clientData.status} />
            </div>
            <div className="relative">
              <button
                onClick={() => setShowOptions(!showOptions)}
                className="p-1 rounded hover:bg-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </button>
              {/* Dropdown Menu */}
              {showOptions && (
                <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg">
                  <button
                    onClick={handleEditToggle}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Edit
                  </button>
                  <button
                    onClick={handleDelete}
                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
          <p>
            <span className="block text-gray-500">Phone:</span>{" "}
            {clientData.phone || "---"}
          </p>
          <p>
            <span className="block text-gray-500">Email:</span>{" "}
            {clientData.email || "---"}
          </p>
          <p>
            <span className="block text-gray-500">Address:</span>{" "}
            {clientData.address || "---"}
          </p>
        </div>
      )}

      {/* Render Tabs if not editing */}
      {!isEditing && <Tabs tabs={tabs} />}
    </div>
  );
};

export default ClientDetails;
