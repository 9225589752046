import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider"; // Assuming you have an AuthContext
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// API Utility Functions with Token Integration
const createPhase = async ({ companyId, data, token }) => {
  if (!companyId || !data) {
    throw new Error("Company ID and phase data are required.");
  }
  const response = await axios.post(
    `${API_URL}/companies/${companyId}/phases`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const fetchPhases = async (companyId, token) => {
  if (!companyId) {
    throw new Error("Company ID is required.");
  }
  const response = await axios.get(`${API_URL}/companies/${companyId}/phases`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updatePhase = async ({ phaseId, data, token }) => {
  if (!phaseId || !data) {
    throw new Error("Phase ID and updated data are required.");
  }
  const response = await axios.put(`${API_URL}/phases/${phaseId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const deletePhase = async (phaseId, token) => {
  if (!phaseId) {
    throw new Error("Phase ID is required.");
  }
  const response = await axios.delete(`${API_URL}/phases/${phaseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// React Query Hooks with Token Integration

export const useCreatePhase = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation(
    ({ companyId, data }) =>
      createPhase({ companyId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("phases");
      },
      onError: (error) => {
        console.error("Error creating phase:", error);
      },
    }
  );
};

export const usePhases = (companyId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["phases", companyId],
    () => fetchPhases(companyId, user?.token),
    {
      enabled: !!companyId && !!user?.token,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdatePhase = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation(
    ({ phaseId, data }) => updatePhase({ phaseId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("phases");
      },
      onError: (error) => {
        console.error("Error updating phase:", error);
      },
    }
  );
};

export const useDeletePhase = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((phaseId) => deletePhase(phaseId, user?.token), {
    onSuccess: () => {
      queryClient.invalidateQueries("phases");
    },
    onError: (error) => {
      console.error("Error deleting phase:", error);
    },
  });
};
