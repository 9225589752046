import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { getInitials } from "../../../../utils/helpers";
import TaskModal from "./taskModal/TaskModal";
import {
  useBatchUpdateTasks,
  useCreateTask,
} from "../../../../hooks/useTasks/useTasks";
import { format } from "date-fns";
import TaskCreationModal from "./taskModal/TaskCreationModal";

const TaskBoard = ({ projectId, data = [] }) => {
  const sortedTasks = (data) => data.sort((a, b) => a.position - b.position);

  const [tasks, setTasks] = useState(sortedTasks(data));
  const [selectedTask, setSelectedTask] = useState(null);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTaskGroup, setNewTaskGroup] = useState("");

  useEffect(() => {
    if (JSON.stringify(tasks) !== JSON.stringify(data)) {
      setTasks(sortedTasks(data));
    }
  }, [data]);

  const createTaskMutation = useCreateTask();
  // const updateTaskMutation = useUpdateTask();
  const batchUpdateTasksMutation = useBatchUpdateTasks();

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // If no destination, do nothing
    if (!destination) return;

    const sourceStatus = source.droppableId;
    const destinationStatus = destination.droppableId;

    // If dropped in the same position, do nothing
    if (
      sourceStatus === destinationStatus &&
      source.index === destination.index
    ) {
      return;
    }

    // Create a copy of the tasks to work with
    const updatedTasks = [...tasks];

    // Find the dragged task
    const draggedTaskIndex = updatedTasks.findIndex(
      (task) => task.id === result.draggableId
    );
    const draggedTask = updatedTasks[draggedTaskIndex];

    if (!draggedTask) return;

    // Remove the dragged task from its original position
    updatedTasks.splice(draggedTaskIndex, 1);

    if (sourceStatus === destinationStatus) {
      // Reorder within the same status
      const tasksOfGroup = updatedTasks.filter(
        (task) => task.status === sourceStatus
      );

      // Insert the dragged task into the new position
      tasksOfGroup.splice(destination.index, 0, draggedTask);

      // Reassign positions within the group
      tasksOfGroup.forEach((task, index) => {
        task.position = index + 1;
      });

      // Update the state immediately
      setTasks((prevTasks) => {
        const otherTasks = prevTasks.filter(
          (task) => task.status !== sourceStatus
        );
        return [...otherTasks, ...tasksOfGroup];
      });

      // Send updated positions to the back-end
      batchUpdateTasksMutation.mutate(tasksOfGroup);
    } else {
      // Move the task to a new group
      const sourceTasks = updatedTasks.filter(
        (task) => task.status === sourceStatus
      );
      const destinationTasks = updatedTasks.filter(
        (task) => task.status === destinationStatus
      );

      // Adjust positions for the source group
      sourceTasks.forEach((task, index) => {
        task.position = index + 1;
      });

      // Add the dragged task to the destination group
      destinationTasks.splice(destination.index, 0, {
        ...draggedTask,
        status: destinationStatus,
      });

      // Adjust positions for the destination group
      destinationTasks.forEach((task, index) => {
        task.position = index + 1;
      });

      // Update the state immediately
      setTasks((prevTasks) => {
        const otherTasks = prevTasks.filter(
          (task) =>
            task.status !== sourceStatus && task.status !== destinationStatus
        );
        return [...otherTasks, ...sourceTasks, ...destinationTasks];
      });

      // Batch update both source and destination groups in the back-end
      batchUpdateTasksMutation.mutate([...sourceTasks, ...destinationTasks]);
    }
  };

  const handleAddTask = (group) => {
    setIsAddingTask(true);
    setNewTaskGroup(group);
  };

  const handleTaskSubmit = (taskData) => {
    createTaskMutation.mutate(
      { ...taskData, status: newTaskGroup },
      {
        onSuccess: (newTask) => {
          setTasks((prevTasks) => [...prevTasks, newTask]);
          setIsAddingTask(false);
        },
        onError: (error) => {
          console.error("Error adding task:", error);
        },
      }
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {/* Horizontal scroll container */}
        <div className="flex space-x-4 overflow-x-auto py-4">
          <TaskGroup
            projectId={projectId}
            title="To Do's"
            tasks={
              Array.isArray(tasks)
                ? tasks?.filter((task) => task?.status === "todos")
                : []
            }
            droppableId="todos"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("todos")}
          />
          <TaskGroup
            projectId={projectId}
            title="In Progress"
            tasks={
              Array.isArray(tasks)
                ? tasks?.filter((task) => task?.status === "inProgress")
                : []
            }
            droppableId="inProgress"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("inProgress")}
          />
          <TaskGroup
            projectId={projectId}
            title="In Review"
            tasks={
              Array.isArray(tasks)
                ? tasks?.filter((task) => task?.status === "inReview")
                : []
            }
            droppableId="inReview"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("inReview")}
          />
          <TaskGroup
            projectId={projectId}
            title="Completed"
            tasks={
              Array.isArray(tasks)
                ? tasks?.filter((task) => task?.status === "completed")
                : []
            }
            droppableId="completed"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("completed")}
          />
        </div>
      </DragDropContext>
      {!!selectedTask && (
        <TaskModal
          taskId={selectedTask?.id}
          onClose={() => setSelectedTask(null)}
        />
      )}
      {isAddingTask && (
        <TaskCreationModal
          projectId={projectId}
          onClose={() => setIsAddingTask(false)}
          onSubmit={handleTaskSubmit}
        />
      )}
    </>
  );
};

const TaskGroup = ({
  projectId,
  title,
  tasks,
  droppableId,
  setSelectedTask,
  onAddTask,
}) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="bg-gray-100 p-4 rounded-lg shadow min-h-[200px] flex flex-col min-w-[250px]"
        >
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <h2 className="text-gray-500 font-bold mr-2">{title}</h2>
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-md">
                {tasks?.length || 0}
              </span>
            </div>
          </div>

          <div className="flex-grow">
            {Array.isArray(tasks) &&
              tasks?.map((task, index) => (
                <Draggable
                  key={task?.id?.toString()}
                  draggableId={task?.id?.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="bg-white p-3 mb-2 rounded-md border border-gray-200 cursor-pointer"
                      onClick={() => setSelectedTask(task)}
                    >
                      <Task task={task} isProjectDetails={projectId} />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided?.placeholder}
          </div>
          {projectId && (
            <button
              onClick={onAddTask}
              className="mt-2 bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-500 font-medium py-1 px-3 rounded-md w-full"
            >
              {`+ Add Task`}
            </button>
          )}
        </div>
      )}
    </Droppable>
  );
};

const Task = ({ task, isProjectDetails }) => {
  return (
    <div role="button">
      <div>
        {!isProjectDetails && (
          <div className="flex items-center text-xs text-gray-500">
            <span className="mb-1 italic truncate overflow-ellipsis whitespace-nowrap">
              {task?.project_name}
            </span>
          </div>
        )}
        <div className="flex items-center text-sm font-semibold text-gray-900">
          <span className="mb-1">{task?.title}</span>
        </div>
        {task?.phase && (
          <div className="mb-1">
            <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
              {task?.phase?.name}
            </span>
          </div>
        )}

        {(task?.subtasks?.length > 0 ||
          task?.users?.length > 0 ||
          task?.end_date) && <hr className="my-3" />}

        <div className="flex items-center justify-between">
          {/* Left Section (Subtasks & End Date) */}
          <div className="flex items-center space-x-4">
            {/* Subtasks */}
            {task?.subtasks?.length > 0 && (
              <span className="flex items-center text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span className="ml-1 text-xs">
                  {`${
                    task?.subtasks?.filter((subtask) => !!subtask?.is_done)
                      ?.length
                  } of ${task?.subtasks?.length}`}
                </span>
              </span>
            )}

            {/* End Date */}
            {task?.end_date && (
              <div className="flex items-center text-xs text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 mr-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                  />
                </svg>
                {format(new Date(task?.end_date), "MMM d")}
              </div>
            )}
          </div>

          {/* Right Section (User Avatars) */}
          {task?.users && (
            <div className="flex -space-x-3">
              {task?.users?.map((user) => (
                <div key={user?.id}>
                  <span className="sr-only">Open user menu</span>
                  <span
                    className="size-5 rounded-full bg-blue-600 text-white flex items-center justify-center border border-white"
                    style={{ fontSize: "8px" }}
                  >
                    {getInitials(user?.username)}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskBoard;
