import React, { useContext } from "react";
import TaskList from "../../todoList/viewMode/TaskList";
import TaskBoard from "../../todoList/viewMode/TaskBoard";
import { useTasksOfProject } from "../../../../hooks/useTasks/useTasks";
import LoadingPage from "../../../../components/common/empty/LoadingPage";
import ErrorMessage from "../../../../components/common/empty/ErrorMessage";
import GanttView from "../../todoList/viewMode/GanttView";
import { usePhases } from "../../../../hooks/useTasks/usePhases";
import { AuthContext } from "../../../../context/AuthProvider";

const ProjectTasks = ({ projectId, viewMode }) => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;

  const { data: tasks, isLoading, isError } = useTasksOfProject(projectId);

  const { data: phasesData = [] } = usePhases(companyId);
  const phaseData = phasesData || [];

  return (
    <div>
      {isLoading ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <>
          {viewMode === "list" && (
            <TaskList data={tasks} projectId={projectId} />
          )}
          {viewMode === "board" && (
            <TaskBoard data={tasks} projectId={projectId} />
          )}
          {viewMode === "gantt" && (
            <GanttView tasks={tasks} phases={phaseData} />
          )}
        </>
      )}
    </div>
  );
};

export default ProjectTasks;
