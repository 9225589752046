import React, { useContext, useState } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import TaskList from "./viewMode/TaskList";
import TaskBoard from "./viewMode/TaskBoard";
import TasksButtonGroupMenu from "../../../components/common/buttonGroup/TasksButtonGroupMenu";
import { useTasksOfCompany } from "../../../hooks/useTasks/useTasks";
import { AuthContext } from "../../../context/AuthProvider";
import LoadingPage from "../../../components/common/empty/LoadingPage";
import ErrorMessage from "../../../components/common/empty/ErrorMessage";
import { usePhases } from "../../../hooks/useTasks/usePhases";
import GanttView from "./viewMode/GanttView";

const TodoList = () => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;

  const { data: tasks, isLoading, isError } = useTasksOfCompany(companyId);
  const [viewMode, setViewMode] = useState("board");

  const { data: phasesData = [] } = usePhases(companyId);
  const phaseData = phasesData || [];

  return (
    <div>
      <PageHeadings title={`Tasks`} tags={[]} showCta={false}>
        <TasksButtonGroupMenu viewMode={viewMode} setViewMode={setViewMode} />
      </PageHeadings>
      {isLoading ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <>
          {viewMode === "list" && <TaskList data={tasks} />}
          {viewMode === "board" && <TaskBoard data={tasks} />}
          {viewMode === "gantt" && (
            <GanttView tasks={tasks} phases={phaseData} />
          )}
        </>
      )}
    </div>
  );
};

export default TodoList;
