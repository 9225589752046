import React from "react";

const PageHeadings = ({
  title,
  tags = [],
  showCta = false,
  ctaLabel,
  handleCtaAction,
  children,
}) => {
  return (
    <div className="flex items-center justify-between my-5">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {title}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          {tags?.map((value, i) => (
            <div
              key={i}
              className="mt-2 flex items-center text-sm text-gray-500"
            >
              {value}
            </div>
          ))}
        </div>
      </div>

      <div className="flex sm:ml-4 sm:mt-0">
        {children}
        {showCta && (
          <span>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ml-1"
              onClick={handleCtaAction}
            >
              {ctaLabel}
            </button>
          </span>
        )}
      </div>
    </div>
  );
};

export default PageHeadings;
