import React, { useState } from "react";
import { useUpdateTask } from "../../../../../../hooks/useTasks/useTasks";

const TaskTitle = ({ title, projectName, onClose, taskId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [originalTitle, setOriginalTitle] = useState(title);
  const { mutate: updateTask } = useUpdateTask();

  const handleSaveTitle = () => {
    if (!newTitle.trim()) {
      setNewTitle(originalTitle);
      setIsEditing(false);
      return;
    }

    setIsEditing(false);

    updateTask(
      { taskId, data: { title: newTitle } },
      {
        onSuccess: () => {
          setOriginalTitle(newTitle);
        },
        onError: () => {
          setNewTitle(originalTitle);
        },
      }
    );
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setNewTitle(originalTitle);
  };

  return (
    <div className="w-full text-sm text-gray-500 mb-2">
      <div className="flex items-center justify-between">
        <div>{projectName}</div>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 flex md:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      {isEditing ? (
        <input
          type="text"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onBlur={handleSaveTitle}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSaveTitle();
            if (e.key === "Escape") handleCancelEdit();
          }}
          className="mt-1 w-full border-b-2 text-base font-semibold text-2xl text-gray-900 focus:outline-none"
          autoFocus
        />
      ) : (
        <h2
          role="button"
          className="mt-1 text-2xl font-semibold text-gray-900 cursor-pointer"
          onClick={() => setIsEditing(true)}
        >
          {newTitle}
        </h2>
      )}
    </div>
  );
};

export default TaskTitle;
