import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateClient } from "../../../hooks/useClients/useClients";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CreateClient = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    status: "Lead",
  });
  const [error, setError] = useState({});
  const [defaultCountry, setDefaultCountry] = useState("us");
  const createClient = useCreateClient();
  const navigate = useNavigate();

  useEffect(() => {
    const getDefaultCountry = () => {
      const locale = navigator.language || navigator.userLanguage;
      const country = locale.split("-")[1]?.toLowerCase();
      if (country) {
        setDefaultCountry(country);
      }
    };
    getDefaultCountry();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({ ...prevData, phone: value }));
  };

  const validateInputs = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required.";
    }
    // Email is optional so validate only if a value is provided.
    if (formData.email && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Enter a valid email.";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required.";
    }
    if (!formData.status) {
      errors.status = "Status is required.";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    if (!validateInputs()) return;

    try {
      await createClient.mutateAsync(formData);
      navigate("/clients");
    } catch (error) {
      setError({ submit: "Failed to create client. Please try again." });
    }
  };

  return (
    <div>
      <PageHeadings title="Add New Client" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {error.submit && (
            <div
              className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Error</span>
              <div>{error.submit}</div>
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Client Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client Name
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter client name"
                value={formData.name}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.name && (
                <div className="text-red-500 text-sm mt-1">{error.name}</div>
              )}
            </div>

            {/* Client Status */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client Status
              </label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <optgroup label="Prospect Statuses">
                  <option value="Lead">Lead</option>
                  <option value="Prospect">Prospect</option>
                  <option value="Potential Client">Potential Client</option>
                  <option value="Proposal Sent">Proposal Sent</option>
                  <option value="Negotiation">Negotiation</option>
                </optgroup>
                <optgroup label="Client Statuses">
                  <option value="Active Client">Active Client</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Repeat Client">Repeat Client</option>
                  <option value="High Priority">High Priority</option>
                  <option value="On Hold">On Hold</option>
                  <option value="Pending Payment">Pending Payment</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Churned">Churned</option>
                  <option value="Past Client">Past Client</option>
                  <option value="VIP Client">VIP Client</option>
                </optgroup>
              </select>
              {error.status && (
                <div className="text-red-500 text-sm mt-1">{error.status}</div>
              )}
            </div>

            {/* Client Phone */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone
              </label>
              <PhoneInput
                country={defaultCountry}
                value={formData.phone}
                onChange={handlePhoneChange}
                placeholder="Enter client phone number"
                inputClass="w-full border p-2 rounded"
                enableSearch={true}
              />
              {error.phone && (
                <div className="text-red-500 text-sm mt-1">{error.phone}</div>
              )}
            </div>

            {/* Client Email (Optional) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email (optional)
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter client email"
                value={formData.email}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.email && (
                <div className="text-red-500 text-sm mt-1">{error.email}</div>
              )}
            </div>

            {/* Client Address */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address (optional)
              </label>
              <input
                type="text"
                name="address"
                placeholder="Enter client address"
                value={formData.address}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Client
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateClient;
