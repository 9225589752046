import React from "react";

const CardHeadings = ({
  title,
  description,
  showCta = false,
  ctaLabel,
  handleCtaAction,
}) => {
  return (
    <div className="bg-white p-4 rounded-lg">
      <div className="flex items-center justify-between rounded-t-lg">
        <div>
          <h3 className="text-lg font-bold text-gray-900">{title}</h3>
          {description && (
            <p className="max-w-2xl text-sm leading-6 text-gray-500 my-2">
              {description}
            </p>
          )}
        </div>
        <div className="flex sm:ml-4 sm:mt-0">
          {showCta && (
            <span>
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ml-1"
                onClick={handleCtaAction}
              >
                {ctaLabel}
              </button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardHeadings;
