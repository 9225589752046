import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import axiosInstance from "../axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch all invoices for the user's company with pagination
const fetchCompanyInvoices = async (page, perPage, search = "", token) => {
  const response = await axiosInstance.get(
    `${API_URL}/company/invoices?page=${page}&per_page=${perPage}&search=${encodeURIComponent(
      search
    )}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch all invoices for a specific project with pagination
const fetchInvoicesByProject = async (projectId, page, perPage, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/projects/${projectId}/invoices?page=${page}&per_page=${perPage}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch all invoices for a specific client with pagination
const fetchInvoicesByClient = async (clientId, page, perPage, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/clients/${clientId}/invoices?page=${page}&per_page=${perPage}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch a single invoice by ID
const fetchInvoice = async (invoiceId, token) => {
  const response = await axiosInstance.get(`${API_URL}/invoices/${invoiceId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Create a new invoice
const createInvoice = async ({ data, token }) => {
  const response = await axiosInstance.post(`${API_URL}/invoices`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Update an existing invoice
const updateInvoice = async ({ invoiceId, data, token }) => {
  const response = await axiosInstance.put(
    `${API_URL}/invoices/${invoiceId}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Delete an invoice
const deleteInvoice = async ({ invoiceId, token }) => {
  await axiosInstance.delete(`${API_URL}/invoices/${invoiceId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// React Query hooks

// Fetch all invoices for the user's company
export const useCompanyInvoices = (page = 1, perPage = 10, search = "") => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["companyInvoices", page, perPage, search],
    () => fetchCompanyInvoices(page, perPage, search, user?.token),
    { enabled: !!user?.token }
  );
};

// Fetch invoices for a specific project
export const useInvoicesByProject = (projectId, page = 1, perPage = 10) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["projectInvoices", projectId, page, perPage],
    () => fetchInvoicesByProject(projectId, page, perPage, user?.token),
    { enabled: !!user?.token && !!projectId }
  );
};

// Fetch invoices for a specific client
export const useInvoicesByClient = (clientId, page = 1, perPage = 10) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["clientInvoices", clientId, page, perPage],
    () => fetchInvoicesByClient(clientId, page, perPage, user?.token),
    { enabled: !!user?.token && !!clientId }
  );
};

// Fetch a single invoice
export const useInvoice = (invoiceId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["invoice", invoiceId],
    () => fetchInvoice(invoiceId, user?.token),
    {
      enabled: !!user?.token && !!invoiceId,
    }
  );
};

// Create a new invoice
export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation((data) => createInvoice({ data, token: user?.token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("companyInvoices");
      queryClient.invalidateQueries("projectInvoices");
    },
  });
};

// Update an existing invoice
export const useUpdateInvoice = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ invoiceId, data }) =>
      updateInvoice({ invoiceId, data, token: user?.token }),
    {
      onSuccess: (data, { invoiceId }) => {
        queryClient.invalidateQueries(["invoice", invoiceId]);
        queryClient.invalidateQueries("companyInvoices");
        queryClient.invalidateQueries("projectInvoices");
      },
    }
  );
};

// Delete an invoice
export const useDeleteInvoice = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ invoiceId }) => deleteInvoice({ invoiceId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("companyInvoices");
        queryClient.invalidateQueries("projectInvoices");
      },
    }
  );
};
