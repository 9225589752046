import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../layout/Sidebar";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Breadcrumb from "../layout/Breadcrumb";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DashboardLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSidebarOpen(false);
      }
    };

    if (sidebarOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [sidebarOpen]);

  return (
    <div className="w-full bg-slate-50">
      <div className="h-full lg:ml-72">
        <header className="contents lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex">
          <div className="bg-white contents lg:pointer-events-auto lg:block lg:w-72 lg:overflow-y-auto border-r border-zinc-900/10 px-4 pb-8 pt-4">
            <div className="hidden lg:flex">
              <span className="self-center text-xl font-semibold whitespace-nowrap mx-auto">
                {"Plura.design"}
              </span>
            </div>
            <Sidebar containerRef={containerRef} sidebarOpen={sidebarOpen} />
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <Breadcrumb />
          </div>
        </header>
        <div className="relative flex min-h-screen flex-col px-4 pt-14">
          <main className="flex-auto">
            <div className="flex h-full flex-col pb-10 pt-16">{children}</div>
          </main>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DashboardLayout;
