import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider"; // Assuming AuthContext exists
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// API Utility Functions with Token Integration

// Fetch all comments of a specific task
const fetchCommentsOfTask = async (taskId, token) => {
  if (!taskId) {
    throw new Error("Task ID is required.");
  }
  const response = await axios.get(`${API_URL}/tasks/${taskId}/comments`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Create a comment
const createComment = async (data, token) => {
  if (!data || !data.task_id) {
    throw new Error("Comment data and task ID are required.");
  }
  const response = await axios.post(`${API_URL}/comments`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Update a comment
const updateComment = async ({ commentId, data, token }) => {
  if (!commentId || !data) {
    throw new Error("Comment ID and data are required.");
  }
  const response = await axios.put(`${API_URL}/comments/${commentId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Delete a comment
const deleteComment = async (commentId, token) => {
  if (!commentId) {
    throw new Error("Comment ID is required.");
  }
  const response = await axios.delete(`${API_URL}/comments/${commentId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// React Query Hooks with Token Integration

// Fetch all comments of a specific task
export const useCommentsOfTask = (taskId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["commentsOfTask", taskId],
    () => fetchCommentsOfTask(taskId, user?.token),
    {
      enabled: !!taskId && !!user?.token,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

// Create a new comment
export const useCreateComment = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((data) => createComment(data, user?.token), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["commentsOfTask", variables.task_id]);
      queryClient.invalidateQueries("tasksOfCompany");
      queryClient.invalidateQueries("tasksOfProject");
      queryClient.invalidateQueries("task");
    },
    onError: (error) => {
      console.error("Error creating comment:", error);
    },
  });
};

// Update a specific comment
export const useUpdateComment = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation(
    ({ commentId, data }) =>
      updateComment({ commentId, data, token: user?.token }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          "commentsOfTask",
          variables.data.task_id,
        ]);
        queryClient.invalidateQueries("tasksOfCompany");
        queryClient.invalidateQueries("tasksOfProject");
        queryClient.invalidateQueries("task");
      },
      onError: (error) => {
        console.error("Error updating comment:", error);
      },
    }
  );
};

// Delete a specific comment
export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((commentId) => deleteComment(commentId, user?.token), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["commentsOfTask", variables.task_id]);
      queryClient.invalidateQueries("tasksOfCompany");
      queryClient.invalidateQueries("tasksOfProject");
      queryClient.invalidateQueries("task");
    },
    onError: (error) => {
      console.error("Error deleting comment:", error);
    },
  });
};
