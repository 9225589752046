import React, { useEffect, useRef } from "react";
import { useTask } from "../../../../../hooks/useTasks/useTasks";
import TaskTitle from "./editTaskSections/TaskTitle";
import TaskDescription from "./editTaskSections/TaskDescription";
import TaskSubtasks from "./editTaskSections/TaskSubtasks";
import TaskComments from "./editTaskSections/TaskComments";
import TaskSidebar from "./editTaskSections/TaskSidebar";

const TaskModal = ({ taskId, onClose }) => {
  const modalRef = useRef();
  const { data: task, isLoading } = useTask(taskId);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (isLoading) return <p>Loading...</p>;
  if (!task) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-gray-50 shadow-lg w-full max-w-5xl h-auto max-h-screen md:rounded-md overflow-y-auto"
      >
        <div className="bg-white flex flex-col md:flex-row h-full">
          <div className="flex-1 p-4">
            <TaskTitle
              taskId={taskId}
              title={task?.title}
              projectName={task?.project_name}
              onClose={onClose}
            />
            <TaskDescription description={task?.description} taskId={taskId} />
            <TaskSubtasks taskId={taskId} />
            <TaskComments taskId={taskId} />
          </div>
          <div className="w-full md:max-w-xs bg-gray-50 p-4 border-t md:border-t-0 md:border-l">
            <TaskSidebar task={task} onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;
