import React from "react";
import { Link } from "react-router-dom";
import { format, parse } from "date-fns";
import Badge from "../badges/Badge";

export const TableRows = ({ data, onClick, hasCheckbox }) => {
  return (
    <tr
      role="button"
      onClick={() => onClick(data)}
      className="bg-white border-b hover:bg-gray-100"
    >
      {hasCheckbox && (
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id={`checkbox-${data?.id}`}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor={`checkbox-${data?.id}`} className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      )}
      <th
        scope="row"
        className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
      >
        <div>
          <div className="text-base font-semibold">{data?.name}</div>
          {data?.email && (
            <div className="text-xs font-normal text-gray-500 mb-0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mr-1 inline-block"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
              {data?.email}
            </div>
          )}
          {data?.phone && (
            <div className="text-xs font-normal text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mr-1 inline-block"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>

              {data?.phone}
            </div>
          )}
        </div>
      </th>
      <td className="px-6 py-4">{data?.projects?.length || 0}</td>
      <td className="px-6 py-4">{data?.invoices?.length || 0}</td>
      <td className="px-6 py-4">
        <Badge status={data?.status} />
      </td>
    </tr>
  );
};

export const ProvidersTableRow = ({ data, onClick, hasCheckbox }) => {
  return (
    <tr
      role="button"
      onClick={() => onClick(data)}
      className="bg-white border-b hover:bg-gray-100"
    >
      {hasCheckbox && (
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id={`checkbox-${data?.id}`}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor={`checkbox-${data?.id}`} className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      )}
      <th
        scope="row"
        className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
      >
        <div>
          <div className="text-base font-semibold">{data?.contact_name}</div>
          {data?.email && (
            <div className="text-xs font-normal text-gray-500 mb-0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mr-1 inline-block"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
              {data?.email}
            </div>
          )}
          {data?.phone && (
            <div className="text-xs font-normal text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mr-1 inline-block"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>

              {data?.phone}
            </div>
          )}
        </div>
      </th>
      <td className="px-6 py-4">
        {data?.company_name}
        {data?.website && (
          <Link
            to={data?.website}
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium text-blue-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </Link>
        )}
      </td>
      <td className="px-6 py-4">
        {`${data?.country ? data?.country : "---"} ${
          data?.city ? `, ${data?.city}` : ""
        }`}
      </td>
      <td className="px-6 py-4">
        {Object.keys(data.services || {}).length > 0 ? (
          <>
            {/* Show first service */}
            <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 mb-1 px-2.5 py-0.5 rounded inline-flex whitespace-nowrap">
              {Object.keys(data.services)[0]}
            </span>

            {/* Show "+n" if there are more services */}
            {Object.keys(data.services).length > 1 && (
              <span className="text-xs text-gray-500 ml-1">
                +{Object.keys(data.services).length - 1} more
              </span>
            )}
          </>
        ) : (
          <p className="text-gray-600">---</p>
        )}
      </td>
    </tr>
  );
};

const getPaymentBadgeClass = (paymentType) => {
  switch (paymentType) {
    case "Credit Card":
      return "bg-blue-100 text-blue-800";
    case "Bank Transfer":
      return "bg-green-100 text-green-800";
    case "Cash":
      return "bg-yellow-100 text-yellow-800";
    case "Check":
      return "bg-gray-200 text-gray-800";
    case "Wire Transfer":
      return "bg-purple-100 text-purple-800";
    case "Mobile Payment":
      return "bg-pink-100 text-pink-800";
    case "Digital Wallet":
      return "bg-indigo-100 text-indigo-800";
    case "Other":
      return "bg-gray-100 text-gray-500";
    default:
      return "bg-gray-100 text-gray-500";
  }
};

export const PurchaseOrdersRow = ({ data, onClick, hasCheckbox }) => {
  return (
    <tr
      role="button"
      onClick={() => onClick(data)}
      className="bg-white border-b hover:bg-gray-100"
    >
      {hasCheckbox && (
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id={`checkbox-${data?.id}`}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor={`checkbox-${data?.id}`} className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      )}
      <th className="px-6 py-4 whitespace-nowrap truncate">
        {data?.order_id || "---"}
      </th>
      <td className="px-6 py-4 whitespace-nowrap">
        {data?.creation_date
          ? new Date(data.creation_date).toLocaleDateString()
          : "---"}
      </td>
      <td className="px-6 py-4">{data?.provider?.company_name || "---"}</td>
      <td className="px-6 py-4">
        {data?.amount ? `$${parseFloat(data.amount).toLocaleString()}` : "$0"}
      </td>
      <td className="px-6 py-4">
        <span
          className={`text-xs font-medium px-2.5 py-0.5 rounded ${getStatusBadgeClass(
            data?.status
          )}`}
        >
          {data?.status || "---"}
        </span>
      </td>
      <td className="px-6 py-4">
        {data?.items && Object.keys(data.items).length > 0
          ? `${Object.values(data.items)[0].name}${
              Object.keys(data.items).length > 1
                ? ` + ${Object.keys(data.items).length - 1}`
                : ""
            }`
          : "---"}
      </td>
      <td className="px-6 py-4">
        <span
          className={`text-xs font-medium px-2.5 py-0.5 rounded whitespace-nowrap ${getPaymentBadgeClass(
            data?.payment_type
          )}`}
        >
          {data?.payment_type || "---"}
        </span>
      </td>
    </tr>
  );
};

export const InvoiceRow = ({ data, onClick, hasCheckbox }) => {
  return (
    <tr
      role="button"
      onClick={() => onClick(data)}
      className="bg-white border-b hover:bg-gray-100"
    >
      {hasCheckbox && (
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id={`checkbox-${data?.id}`}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor={`checkbox-${data?.id}`} className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      )}
      <th className="px-6 py-4 whitespace-nowrap">{data?.invoice_number}</th>
      <td className="px-6 py-4 whitespace-nowrap">
        {data?.discount_type === "percentage"
          ? `${Math.round(data?.discount_value) || 0}%`
          : `$${new Intl.NumberFormat().format(data?.discount_value) || 0}`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        $
        {data?.final_amount
          ? new Intl.NumberFormat().format(data?.final_amount)
          : 0}
      </td>
      <td className="px-6 py-4">
        <span
          className={`text-xs font-medium px-2.5 py-0.5 rounded ${getStatusBadgeClass(
            data?.status
          )}`}
        >
          {data?.status}
        </span>
      </td>
      <td className="px-6 py-4">
        <b className="block">{data?.client?.name}</b>
        {data?.client?.email && <small>{data?.client?.email}</small>}
      </td>
      <td className="px-6 py-4">
        {data?.issue_date
          ? new Date(data?.issue_date).toLocaleDateString()
          : "---"}
      </td>
      <td className="px-6 py-4">
        {data?.due_date ? new Date(data?.due_date).toLocaleDateString() : "---"}
      </td>
    </tr>
  );
};

export const InvoiceMinRow = ({ data, onClick, hasCheckbox }) => {
  return (
    <tr onClick={() => onClick(data)} className="bg-white border-b">
      {hasCheckbox && (
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id={`checkbox-${data?.id}`}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor={`checkbox-${data?.id}`} className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      )}
      <th className="px-6 py-4 whitespace-nowrap">
        <span className="block">{data?.invoice_number}</span>
        <span
          className={`text-xs font-medium px-2.5 py-0.5 rounded ${getStatusBadgeClass(
            data?.status
          )}`}
        >
          {data?.status}
        </span>
      </th>
      <td className="px-6 py-4 whitespace-nowrap">
        {data?.discount_type === "percentage"
          ? `${Math.round(data?.discount_value) || 0}%`
          : `$${new Intl.NumberFormat().format(data?.discount_value) || 0}`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        $
        {data?.final_amount
          ? new Intl.NumberFormat().format(data?.final_amount)
          : 0}
      </td>
      <td className="px-6 py-4">
        {data?.issue_date
          ? new Date(data?.issue_date).toLocaleDateString()
          : "---"}
      </td>
      <td className="px-6 py-4">
        {data?.due_date ? new Date(data?.due_date).toLocaleDateString() : "---"}
      </td>
    </tr>
  );
};

export const getStatusBadgeClass = (status) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "bg-yellow-100 text-yellow-800";
    case "shipped":
      return "bg-blue-100 text-blue-800";
    case "delivered":
      return "bg-green-100 text-green-800";
    case "overdue":
      return "bg-red-100 text-red-800";
    case "processing":
      return "bg-indigo-100 text-indigo-800";
    case "paid":
      return "bg-green-100 text-green-800";
    case "cancelled":
      return "bg-gray-100 text-gray-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export const FileRow = ({ data, onClick }) => {
  const getFileIcon = (fileType) => {
    if (fileType === "pdf") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
      );
    } else if (fileType === "link") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
          />
        </svg>
      );
    } else if (fileType === "image") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>
      );
    } else
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
          />
        </svg>
      );
  };

  function getLastUpdatedText(date) {
    try {
      const dateFormat = "EEE, dd MMM yyyy HH:mm:ss 'GMT'";
      const parsedDate = parse(date, dateFormat, new Date());

      if (isNaN(parsedDate.getTime())) {
        return "Invalid date provided";
      }

      const formattedDate = format(parsedDate, "dd MMM yyyy");
      return `Last edited on ${formattedDate}`;
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date provided";
    }
  }

  return (
    <tr
      role="button"
      onClick={() => onClick(data)}
      className="bg-white border-b hover:bg-gray-100"
    >
      <th
        scope="row"
        className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
      >
        {getFileIcon(data.file_type)}
        <div className="text-base font-semibold ml-2">{data.name}</div>
      </th>
      <td className="px-6 py-4">
        <div>
          {data.is_public ? (
            <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
              Public
            </span>
          ) : (
            <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
              Private
            </span>
          )}
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="whitespace-nowrap text-sm text-gray-600">
          {getLastUpdatedText(data.last_modify)}
        </div>
      </td>
    </tr>
  );
};

export const LoadingRows = ({ colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div
          role="status"
          className="bg-white flex items-center justify-center h-40"
        >
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </td>
    </tr>
  );
};

export const ErrorRows = ({ colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className={`bg-white rounded-lg p-6 text-center`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 text-red-500 mx-auto mb-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>

          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            {"Unable to Load Data"}
          </h2>
          <p className="text-gray-600">
            {"Something went wrong, please try again later."}
          </p>
        </div>
      </td>
    </tr>
  );
};

export const EmptyRows = ({ colSpan, message = "No data available" }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="bg-white rounded-lg p-6 text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 text-gray-500 mx-auto mb-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
            />
          </svg>

          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            {message}
          </h2>
          <p className="text-gray-600">
            {"Try adding new data to get started."}
          </p>
        </div>
      </td>
    </tr>
  );
};
