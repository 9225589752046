import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider"; // Assuming AuthContext exists for managing user auth
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// API Utility Functions with Token Integration
const fetchTasksOfCompany = async (companyId, token) => {
  if (!companyId) {
    throw new Error("Company ID is required.");
  }
  const response = await axios.get(`${API_URL}/company/${companyId}/tasks`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const fetchTasksOfProject = async (projectId, token) => {
  if (!projectId) {
    throw new Error("Project ID is required.");
  }
  const response = await axios.get(`${API_URL}/projects/${projectId}/tasks`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const createTask = async (data, token) => {
  if (!data) {
    throw new Error("Task data is required.");
  }
  const response = await axios.post(`${API_URL}/tasks`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const fetchTask = async (taskId, token) => {
  if (!taskId) {
    throw new Error("Task ID is required.");
  }
  const response = await axios.get(`${API_URL}/tasks/${taskId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updateTask = async ({ taskId, data, token }) => {
  if (!taskId || !data) {
    throw new Error("Task ID and data are required.");
  }
  const response = await axios.put(`${API_URL}/tasks/${taskId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const deleteTask = async (taskId, token) => {
  if (!taskId) {
    throw new Error("Task ID is required.");
  }
  const response = await axios.delete(`${API_URL}/tasks/${taskId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// React Query Hooks with Token Integration
export const useTasksOfCompany = (companyId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["tasksOfCompany", companyId],
    () => fetchTasksOfCompany(companyId, user?.token),
    {
      enabled: !!companyId && !!user?.token,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useTasksOfProject = (projectId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["tasksOfProject", projectId],
    () => fetchTasksOfProject(projectId, user?.token),
    {
      enabled: !!projectId && !!user?.token,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useCreateTask = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((data) => createTask(data, user?.token), {
    onSuccess: () => {
      queryClient.invalidateQueries("tasksOfCompany");
      queryClient.invalidateQueries("tasksOfProject");
    },
    onError: (error) => {
      console.error("Error creating task:", error);
    },
  });
};

export const useTask = (taskId) => {
  const { user } = useContext(AuthContext);
  return useQuery(["task", taskId], () => fetchTask(taskId, user?.token), {
    enabled: !!taskId && !!user?.token,
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateTask = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation(
    ({ taskId, data }) => updateTask({ taskId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tasksOfCompany");
        queryClient.invalidateQueries("tasksOfProject");
        queryClient.invalidateQueries("task");
      },
      onError: (error) => {
        console.error("Error updating task:", error);
      },
    }
  );
};

export const useDeleteTask = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((taskId) => deleteTask(taskId, user?.token), {
    onSuccess: () => {
      queryClient.invalidateQueries("tasksOfCompany");
      queryClient.invalidateQueries("tasksOfProject");
    },
    onError: (error) => {
      console.error("Error deleting task:", error);
    },
  });
};

const batchUpdateTasks = async (tasks, token) => {
  if (!tasks || tasks.length === 0) {
    throw new Error("Tasks data is required for batch update.");
  }
  const response = await axios.put(
    `${API_URL}/tasks/batch_update`,
    { tasks },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const useBatchUpdateTasks = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((tasks) => batchUpdateTasks(tasks, user?.token), {
    onSuccess: () => {
      // Invalidate queries to refetch updated tasks
      queryClient.invalidateQueries("tasksOfCompany");
      queryClient.invalidateQueries("tasksOfProject");
    },
    onError: (error) => {
      console.error("Error in batch update tasks:", error);
    },
  });
};
