import React from "react";
import tradePortalImg from "../../../assets/images/tradePortal.png";

const TradePortalComingSoon = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-gray-50 p-4">
      {/* Title */}
      <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
        Trade Portal Coming Soon
      </h1>

      {/* Short Description */}
      <p className="text-center text-lg md:text-xl text-gray-700 max-w-2xl">
        Stuck with your quotes because you lack reliable suppliers? Here you’ll
        find a comprehensive directory specialized for each of your projects.
      </p>

      {/* Feature Preview Image */}
      <div className="my-10">
        <img
          src={tradePortalImg}
          alt="Trade Portal Preview"
          className="rounded-lg shadow-lg max-w-2xl"
        />
      </div>

      {/* Call to Action or Notice */}
      <p className="text-center text-gray-600 text-sm md:text-base">
        We’re hard at work building this feature to improve your project
        workflows. Stay tuned for updates!
      </p>
    </div>
  );
};

export default TradePortalComingSoon;
