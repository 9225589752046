import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider"; // Import AuthContext for token
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// API Utility Functions with Token Integration
const fetchSubtasksOfTask = async (taskId, token) => {
  if (!taskId) {
    throw new Error("Task ID is required.");
  }
  const response = await axios.get(`${API_URL}/tasks/${taskId}/subtasks`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const createSubtask = async (data, token) => {
  if (!data || !data.task_id) {
    throw new Error("Subtask data and task ID are required.");
  }
  const response = await axios.post(`${API_URL}/subtasks`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updateSubtask = async ({ subtaskId, data, token }) => {
  if (!subtaskId || !data) {
    throw new Error("Subtask ID and data are required.");
  }
  const response = await axios.put(`${API_URL}/subtasks/${subtaskId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const deleteSubtask = async (subtaskId, token) => {
  if (!subtaskId) {
    throw new Error("Subtask ID is required.");
  }
  const response = await axios.delete(`${API_URL}/subtasks/${subtaskId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// React Query Hooks with Token Integration

// Fetch all subtasks of a specific task
export const useSubtasksOfTask = (taskId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["subtasksOfTask", taskId],
    () => fetchSubtasksOfTask(taskId, user?.token),
    {
      enabled: !!taskId && !!user?.token,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

// Create a new subtask
export const useCreateSubtask = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((data) => createSubtask(data, user?.token), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["subtasksOfTask", variables.task_id]);
      queryClient.invalidateQueries("tasksOfCompany");
      queryClient.invalidateQueries("tasksOfProject");
      queryClient.invalidateQueries("task");
    },
    onError: (error) => {
      console.error("Error creating subtask:", error);
    },
  });
};

// Update a specific subtask
export const useUpdateSubtask = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation(
    ({ subtaskId, data }) =>
      updateSubtask({ subtaskId, data, token: user?.token }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          "subtasksOfTask",
          variables.data.task_id,
        ]);
        queryClient.invalidateQueries("tasksOfCompany");
        queryClient.invalidateQueries("tasksOfProject");
        queryClient.invalidateQueries("task");
      },
      onError: (error) => {
        console.error("Error updating subtask:", error);
      },
    }
  );
};

// Delete a specific subtask
export const useDeleteSubtask = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((subtaskId) => deleteSubtask(subtaskId, user?.token), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["subtasksOfTask", variables.task_id]);
      queryClient.invalidateQueries("tasksOfCompany");
      queryClient.invalidateQueries("tasksOfProject");
      queryClient.invalidateQueries("task");
    },
    onError: (error) => {
      console.error("Error deleting subtask:", error);
    },
  });
};
