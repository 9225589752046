import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import AsyncSelect from "react-select/async";
import { useCreateExpense } from "../../../hooks/useExpenses/useExpenses";
import { useProjects } from "../../../hooks/useProjects/useProjects";
import { useProviders } from "../../../hooks/useProviders/useProviders";
import "react-phone-input-2/lib/style.css";

const CreatePurchaseOrder = () => {
  // You can update userLocale if needed (for Colombia: "es-CO")
  const userLocale = navigator.language || "es-CO";
  // Change default currency from USD to COP
  const userCurrency = "COP";

  const location = useLocation();
  const paramProjectId = new URLSearchParams(location.search).get("projectId");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    description: "",
    amount: 0,
    due_date: "",
    status: "Pending",
    location: "",
    payment_type: "Credit Card",
    items: {},
    project_id: paramProjectId || "",
    provider_id: "",
  });
  const [error, setError] = useState({});
  // New state for our custom alert message
  const [alertMessage, setAlertMessage] = useState(false);
  const createExpense = useCreateExpense();

  // Fetch projects and providers
  const { data: projectsData } = useProjects(1, 10000);
  const { data: providersData } = useProviders(1, 10000);

  // Set up options for AsyncSelect dropdowns
  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];
  const providerOptions =
    providersData?.providers.map((provider) => ({
      value: provider.id,
      label: provider.company_name,
    })) || [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle changes to items within the form
  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? Math.max(1, parseFloat(value))
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };

      // Calculate total amount as the sum of value * quantity for each item.
      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) =>
          sum +
          (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
        0
      );

      return { ...prevData, items: updatedItems, amount: totalAmount };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: {
          name: "",
          description: "",
          quantity: 1,
          value: 0,
        },
      },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];

      // Recalculate total amount using both value and quantity.
      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) =>
          sum +
          (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
        0
      );
      return { ...prevData, items: updatedItems, amount: totalAmount };
    });
  };

  const validateInputs = () => {
    const errors = {};

    if (!formData.description.trim()) {
      errors.description = "Description is required.";
    }
    if (formData.amount <= 0) {
      errors.amount = "Total amount must be greater than 0.";
    }

    // Check if there are no items at all.
    if (Object.keys(formData.items).length === 0) {
      setAlertMessage(true);
      return false;
    }

    Object.entries(formData.items).forEach(([itemId, item]) => {
      if (!item.name.trim()) {
        errors[`itemName${itemId}`] = "Item name is required.";
      }
      if (!item.quantity || isNaN(item.quantity) || item.quantity <= 0) {
        errors[`itemQuantity${itemId}`] = "Quantity must be greater than 0.";
      }
      if (!item.value || isNaN(item.value) || item.value <= 0) {
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
      }
    });

    if (Object.keys(errors).length > 0) {
      setError(errors);
      // Check for any quantity or value errors and show custom alert if found.
      const quantityOrValueError = Object.keys(errors).some(
        (key) => key.includes("itemQuantity") || key.includes("itemValue")
      );
      if (quantityOrValueError) {
        setAlertMessage(true);
      }
      return false;
    }
    // Clear the alert if everything validates correctly.
    setAlertMessage(false);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    // Optionally, recalculate the total amount again before submitting:
    const recalculatedTotal = Object.values(formData.items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );
    const updatedData = { ...formData, amount: recalculatedTotal };

    if (!validateInputs()) return;

    try {
      await createExpense.mutateAsync(updatedData);

      const redirectUrl = new URLSearchParams(location.search).get("redirect")
        ? `${new URLSearchParams(location.search).get("redirect")}?view=finance`
        : `/purchase-orders`;
      navigate(redirectUrl);
    } catch (error) {
      setError({ submit: "Failed to create expense. Please try again." });
    }
  };

  return (
    <div>
      <PageHeadings title="Add New Expense" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {/* Render the custom alert message if present */}
          {alertMessage && (
            <div
              className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50"
              role="alert"
            >
              One or more items have invalid quantities or values. Please
              correct them and try again.
            </div>
          )}
          {error.submit && (
            <div className="text-red-500 p-4 mb-4 bg-red-50 rounded">
              {error.submit}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Description Field */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                type="text"
                name="description"
                placeholder="Description of the expense"
                value={formData.description}
                onChange={handleChange}
                required
                className="border p-2 w-full rounded max-w-md"
              />
              {error.description && (
                <div className="text-red-500 text-sm mt-1">
                  {error.description}
                </div>
              )}
            </div>

            {/* Status Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                required
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="In Process">In Process</option>
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
              {error.status && (
                <div className="text-red-500 text-sm mt-1">{error.status}</div>
              )}
            </div>

            {/* Payment Due Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Payment Due Date (optional)
              </label>
              <input
                type="date"
                name="due_date"
                placeholder="Select due date for payment"
                value={formData.due_date}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* Payment Type Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Payment Type
              </label>
              <select
                name="payment_type"
                value={formData.payment_type}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="Credit Card">Credit Card</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Cash">Cash</option>
                <option value="Check">Check</option>
                <option value="Wire Transfer">Wire Transfer</option>
                <option value="Mobile Payment">Mobile Payment</option>
                <option value="Digital Wallet">
                  Digital Wallet (e.g., PayPal)
                </option>
                <option value="Other">Other</option>
              </select>
            </div>

            {/* Location */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Location (optional)
              </label>
              <input
                type="text"
                name="location"
                placeholder="Expense location"
                value={formData.location}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* Project Dropdown (Hide if projectId is in params) */}
            {!paramProjectId && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Project (optional)
                </label>
                <AsyncSelect
                  className="max-w-md"
                  cacheOptions
                  defaultOptions={projectOptions}
                  loadOptions={(inputValue, callback) =>
                    callback(
                      projectOptions.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    )
                  }
                  onChange={(selected) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      project_id: selected ? selected.value : "",
                    }))
                  }
                  placeholder="Select a project"
                />
              </div>
            )}

            {/* Provider Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Provider (optional)
              </label>
              <AsyncSelect
                className="max-w-md"
                cacheOptions
                defaultOptions={providerOptions}
                loadOptions={(inputValue, callback) =>
                  callback(
                    providerOptions.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  )
                }
                onChange={(selected) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    provider_id: selected ? selected.value : "",
                  }))
                }
                placeholder="Select a provider"
              />
            </div>

            {/* Items Section */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Items
              </label>
              {/* Responsive Wrapper */}
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="bg-gray-100 text-sm font-medium text-gray-700">
                    <tr>
                      <th className="px-4 py-2">Item Name</th>
                      <th className="px-4 py-2">Quantity</th>
                      <th className="px-4 py-2">Price</th>
                      <th className="px-4 py-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object?.entries(formData?.items)?.map(([itemId, item]) => (
                      <tr
                        key={itemId}
                        className="text-sm text-gray-700 border-b border-gray-200"
                      >
                        {/* Item Name and Description */}
                        <td className="px-4 py-2 align-top">
                          <input
                            type="text"
                            placeholder="Item Name"
                            value={item.name}
                            onChange={(e) =>
                              handleItemChange(itemId, "name", e.target.value)
                            }
                            className="border p-2 w-full min-w-[170px] rounded mb-1"
                          />
                          {error[`itemName${itemId}`] && (
                            <div className="text-red-500 text-xs mb-2">
                              {error[`itemName${itemId}`]}
                            </div>
                          )}
                          <input
                            type="text"
                            placeholder="Description (Optional)"
                            value={item.description}
                            onChange={(e) =>
                              handleItemChange(
                                itemId,
                                "description",
                                e.target.value
                              )
                            }
                            className="border p-2 w-full min-w-[170px] rounded"
                          />
                        </td>
                        {/* Quantity */}
                        <td className="px-4 py-2 align-top">
                          <input
                            type="number"
                            placeholder="Quantity"
                            min="0"
                            value={item.quantity}
                            onChange={(e) =>
                              handleItemChange(
                                itemId,
                                "quantity",
                                e.target.value
                              )
                            }
                            className="border p-2 w-full min-w-[60px] rounded"
                          />
                          {error[`itemQuantity${itemId}`] && (
                            <div className="text-red-500 text-xs">
                              {error[`itemQuantity${itemId}`]}
                            </div>
                          )}
                        </td>
                        {/* Value (Price) */}
                        <td className="px-4 py-2 align-top">
                          <input
                            type="text"
                            placeholder="Item Value"
                            value={
                              item.value !== undefined && item.value !== ""
                                ? new Intl.NumberFormat(userLocale, {
                                    currency: userCurrency,
                                    maximumFractionDigits: 0,
                                  }).format(item.value)
                                : ""
                            }
                            onChange={(e) => {
                              const rawValue = e.target.value.replace(
                                /[^\d.]/g,
                                ""
                              );
                              if (rawValue === "" || isNaN(rawValue)) return;
                              handleItemChange(
                                itemId,
                                "value",
                                parseFloat(rawValue)
                              );
                            }}
                            onBlur={() => {
                              const numericValue = parseFloat(
                                item.value || 0
                              ).toFixed(0);
                              handleItemChange(itemId, "value", numericValue);
                            }}
                            className="border p-2 w-full rounded min-w-[110px]"
                          />

                          {error[`itemValue${itemId}`] && (
                            <div className="text-red-500 text-xs">
                              {error[`itemValue${itemId}`]}
                            </div>
                          )}
                        </td>
                        {/* Actions */}
                        <td className="px-4 py-2 align-top text-center">
                          <button
                            type="button"
                            onClick={() => removeItem(itemId)}
                            className="text-red-500 hover:underline"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                type="button"
                onClick={addItem}
                className="mt-2 text-blue-500 hover:underline"
              >
                Add Item
              </button>
            </div>

            {/* Amount Field (Read-Only) */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Total Amount
              </label>
              <input
                type="text"
                name="amount"
                value={
                  formData.amount
                    ? new Intl.NumberFormat(userLocale, {
                        currency: userCurrency,
                        maximumFractionDigits: 0,
                      }).format(formData.amount)
                    : 0
                }
                readOnly
                disabled
                className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
              />
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={createExpense.isLoading}
            >
              {createExpense.isLoading ? "Adding..." : "Add Expense"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreatePurchaseOrder;
